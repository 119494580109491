/* eslint-disable jsx-a11y/no-redundant-roles */

import { useEffect, useReducer, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

export default function ActionSteps({ sliderRef }) {
  const [active, setActive] = useState(0);
  const [progress, setProgress] = useState(0);

  const data = [
    <FormattedMessage id="scan" />,
    <FormattedMessage id="explore" />,
    <FormattedMessage id="order" />,
  ];
  useEffect(() => {
    const intervalId = setInterval(() => {
      setActive((prev) => {
        if (prev === data.length - 1) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setProgress(0);
    if (window.innerWidth < 720) {
      switch (active) {
        case 0:
          sliderRef.current.style.transform = "translateX(0%)";
          break;
        case 1:
          sliderRef.current.style.transform = "translateX(-60%)";
          break;
        case 2:
          sliderRef.current.style.transform = "translateX(-140%)";
          break;
        default:
          sliderRef.current.style.transform = "translateX(0%)";
      }
    } else {
      switch (active) {
        case 0:
          sliderRef.current.style.transform = "translateX(0%)";
          break;
        case 1:
          sliderRef.current.style.transform = "translateX(-40%)";
          break;
        case 2:
          sliderRef.current.style.transform = "translateX(-110%)";
          break;
        default:
          sliderRef.current.style.transform = "translateX(0%)";
      }
    }

    const start = Date.now();

    const timer = setInterval(function () {
      const timePassed = Date.now() - start;

      if (timePassed >= 10000) {
        setProgress(100);
        clearInterval(timer);
        return;
      }
      setProgress(timePassed / 100);
    }, 20);
  }, [active]);

  return (
    <div className="w-full lg:w-[60%]">
      <div className="mt-6">
        <div
          ref={sliderRef}
          className="wow slideInRight relative flex flex-row duration-300"
        >
          {data.map((elem, index) => {
            if (active === index) {
              return (
                <div
                  key={"step-" + index}
                  className="relative font-medium text-6xl lg:text-8xl text-black mr-6"
                >
                  <p>{elem}</p>
                </div>
              );
            } else
              return (
                <div
                  key={"step-" + index}
                  className="relative font-medium text-6xl lg:text-8xl text-indigo-400 mr-6"
                >
                  <p>{elem}</p>
                </div>
              );
          })}
        </div>
        <div className="overflow-hidden rounded-full w-full bg-indigo-400 mt-[20px] lg:mt-[60px]">
          <div
            className="h-1 rounded-full bg-black"
            style={{ width: `${progress}%` }}
          />
        </div>
        {/* <div className="overflow-hidden rounded-full bg-indigo-400">
          <div className="h-2 rounded-full bg-indigo-900" style={{ width: '55%' }} />
        </div>
        <div className="mt-6 hidden grid-cols-3 font-medium text-2xl text-indigo-200 sm:grid">
          <div className="">SCAN</div>
          <div className="text-center text-white">EXPLORE</div>
          <div className="text-right">ORDER</div>
        </div> */}
      </div>
    </div>
  );
}
