import { useEffect, useRef, useState } from "react";
import phone from "../assets/phoneWithScreen.png";

export default function PhoneActions({ containerRef }) {
    const ref = useRef();
    const [top, setTop] = useState(0);

    useEffect(() => {
        setTop(containerRef.current.getBoundingClientRect().height / 4);
    }, []);

    return (
        <img
            ref={ref}
            style={{
                transition: "ease-in 0.3s",
                opacity: 1,
                top: `${top}px`,
            }}
            className="wow slideInRight absolute h-[300px] w-full md:h-96 lg:top-[20px] lg:inset-y-0 lg:right-0 lg:h-5/6 lg:w-2/5 mb-20 object-contain z-20"
            src={phone}
            alt=""
        />
    );
}
