/* eslint-disable jsx-a11y/alt-text */
import {
  ClockIcon,
  GlobeEuropeAfricaIcon,
  LanguageIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  QrCodeIcon,
} from "@heroicons/react/24/outline";
import sad from "../assets/sad.png";
import sadEmoji from "../assets/sad_emoji.png";
import happyEmoji from "../assets/happy_emoji.png";
import happy from "../assets/happy.png";
import { FormattedMessage } from "react-intl";

export default function Comparison() {
  return (
    <div
      id="comparison"
      className="bg-gray-100 py-24 sm:py-32 lg:py-24 relative overflow-x-hidden"
    >
      <div className="mx-auto max-w-5xl px-6 lg:px-8">
        <div className="sm:text-center">
          <p className="wow fadeIn mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            <FormattedMessage id="stepAhead" />
          </p>
          <p
            className="wow fadeIn mx-auto mt-6 max-w-2xl text-2xl leading-8 text-gray-500"
            style={{ fontFamily: "Open Sans" }}
          >
            <FormattedMessage id="helpCustomers" />
          </p>
        </div>

        <div className="mt-20 max-w-lg mx-auto md:max-w-none">
          <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
            <div className="flex flex-col wow slideInLeft">
              <div
                className="pt-[70%] bg-cover rounded-md mb-8 relative bg-center p-4"
                style={{
                  backgroundImage: `url(${sad})`,
                }}
              >
                <div className="lg:absolute bottom-5 right-5 w-full h-auto md:w-3/5 bg-white rounded-md p-4 flex flex-row items-center ">
                  <div className="inline-block">
                    <FormattedMessage id="soupOrAMain" />
                    <img
                      className="ml-1 mb-1 h-5 inline-block"
                      src={sadEmoji}
                    />
                  </div>
                </div>
              </div>
              <div className="relative flex flex-row gap-6 mb-3">
                <div className="flex h-8 w-8 items-center justify-center rounded-md bg-white border-2 border-indigo-500 text-indigo-500 sm:shrink-0">
                  <GlobeEuropeAfricaIcon
                    className="h-4 w-4"
                    aria-hidden="true"
                  />
                </div>
                <div className="sm:min-w-0 sm:flex-1">
                  <p className="text-lg font-medium leading-8 text-gray-900">
                    <FormattedMessage id="getToRestaurant" />
                  </p>
                </div>
              </div>
              <div className="relative flex flex-row gap-6 mb-3">
                <div className="flex h-8 w-8 items-center justify-center rounded-md bg-white border-2 border-indigo-500 text-indigo-500 sm:shrink-0">
                  <ClockIcon className="h-4 w-4" aria-hidden="true" />
                </div>
                <div className="sm:min-w-0 sm:flex-1">
                  <p className="text-lg font-medium leading-8 text-gray-900">
                    <FormattedMessage id="waitForPaperMenu" />
                  </p>
                </div>
              </div>
              <div className="relative flex flex-row gap-6 mb-3">
                <div className="flex h-8 w-8 items-center justify-center rounded-md bg-white border-2 border-indigo-500 text-indigo-500 sm:shrink-0">
                  <LanguageIcon className="h-4 w-4" aria-hidden="true" />
                </div>
                <div className="sm:min-w-0 sm:flex-1">
                  <p className="text-lg font-medium leading-8 text-gray-900">
                    <FormattedMessage id="translateDishInformation" />
                  </p>
                </div>
              </div>
              <div className="relative flex flex-row gap-6 mb-3">
                <div className="flex h-8 w-8 items-center justify-center rounded-md bg-white border-2 border-indigo-500 text-indigo-500 sm:shrink-0">
                  <FaceFrownIcon className="h-4 w-4" aria-hidden="true" />
                </div>
                <div className="sm:min-w-0 sm:flex-1">
                  <p className="text-lg font-medium leading-8 text-gray-900">
                    <FormattedMessage id="invalidTranslation" />
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col wow slideInRight">
              <div
                className="pt-[70%] bg-cover rounded-md mb-8 relative bg-center p-4"
                style={{
                  backgroundImage: `url(${happy})`,
                }}
              >
                <div className="lg:absolute bottom-5 right-5 w-full h-auto md:w-3/5 bg-white rounded-md p-4 flex flex-row items-center ">
                  <div className="inline-block">
                    <FormattedMessage id="everythingIsClear" />
                    <img
                      className="ml-1 mb-1 h-5 inline-block"
                      src={happyEmoji}
                    />
                  </div>
                </div>
              </div>
              <div className="relative flex flex-row gap-6 mb-3">
                <div className="flex h-8 w-8 items-center justify-center rounded-md bg-white border-2 border-indigo-500 text-indigo-500 sm:shrink-0">
                  <GlobeEuropeAfricaIcon
                    className="h-4 w-4"
                    aria-hidden="true"
                  />
                </div>
                <div className="sm:min-w-0 sm:flex-1">
                  <p className="text-lg font-medium leading-8 text-gray-900">
                    <FormattedMessage id="getToRestaurant" />
                  </p>
                </div>
              </div>
              <div className="relative flex flex-row gap-6 mb-3">
                <div className="flex h-8 w-8 items-center justify-center rounded-md bg-white border-2 border-indigo-500 text-indigo-500 sm:shrink-0">
                  <QrCodeIcon className="h-4 w-4" aria-hidden="true" />
                </div>
                <div className="sm:min-w-0 sm:flex-1">
                  <p className="text-lg font-medium leading-8 text-gray-900">
                    <FormattedMessage id="scanQR" />
                  </p>
                </div>
              </div>
              <div className="relative flex flex-row gap-6 mb-3">
                <div
                  className="flex h-8 w-8 items-center justify-center rounded-md bg-white border-2 border-indigo-500 text-indigo-500 sm:shrink-0"
                  style={{ minWidth: "2rem" }}
                >
                  <FaceSmileIcon className="h-4 w-4" aria-hidden="true" />
                </div>
                <div className="sm:min-w-0 sm:flex-1">
                  <p className="text-lg font-medium leading-8 text-gray-900">
                    <FormattedMessage id="getAllInformation" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
