import "./App.css";
import Header from "./components/Header";
import Hero from "./screens/Hero";
import Features from "./screens/Features";
import Stats from "./screens/Stats";
import { useEffect, useRef, useState } from "react";
import WOW from "wowjs";
import Comparison from "./screens/Comparison";
import { LOCALES } from "./i18n/locale";
import { messages } from "./i18n/messages";
import Advantages from "./screens/Advantages";
import HowItWorks from "./screens/HowItWorks";
import Sell from "./screens/Sell";
import CTA from "./screens/CTA";
import Security from "./screens/Security";
import Actions from "./screens/Actions";
import ActionCTA from "./screens/ActionCTA";
import Products from "./screens/Products";
import FAQ from "./screens/FAQ";
import Contact from "./screens/Contact";
import Footer from "./screens/Footer";
import PhoneHero from "./components/PhoneHero";
import { Spinner } from "./components/Spinner";
import { PrivacyPolicy } from "./screens/PrivacyPolicy";
import { Terms } from "./screens/Terms";
import { ScrollToTopOnReload } from "./components/ScrollToTopOnReload";
import smoothscroll from "smoothscroll-polyfill";
import { Toast } from "./components/Toast";
import { initLocale } from "./helpers";
import { CookiesContext, LocaleContext } from "./context";
import { IntlProvider } from "react-intl";
import { initStateCookies } from "./constants";
import { AnalyticsBlock } from "./components/Cookies/RootPageModule/AnalyticsBlock";

function App() {
  const scrollPhoneContainer = useRef();
  const slider = useRef();
  const [isPhoneHero, setIsPhoneHero] = useState(true);
  const [scrolled, setScrolled] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [titleToast, setTitleToast] = useState("");
  const [messageToast, setMessageToast] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const [successSended, setSuccessSended] = useState(false);
  const [locale, setLocale] = useState("en");

  const [cookiesData, setCookiesData] = useState(
    JSON.parse(
      localStorage.getItem("dm-cookie") || JSON.stringify(initStateCookies)
    )
  );

  let urlPath = window.location.pathname.slice(1).toLowerCase();

  smoothscroll.polyfill();
  window.__forceSmoothScrollPolyfill__ = true;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    new WOW.WOW({
      live: true,
    }).init();

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setScrolled(window.pageYOffset > 20)
      );
    }

    initLocale({ setLocale });
  }, []);

  window.addEventListener("load", () => {
    setLoading(false);
  });

  if (loading) {
    return (
      <div className="h-screen w-screen flex items-center justify-center">
        <Spinner color="bg-primary" />
      </div>
    );
  }

  if (urlPath === "privacy-policy") {
    return (
      <LocaleContext.Provider
        value={{
          locale,
          setLocale,
        }}
      >
        <CookiesContext.Provider value={{ cookiesData, setCookiesData }}>
          <IntlProvider
            messages={messages[locale]}
            locale={locale}
            defaultLocale={LOCALES.ENGLISH}
          >
            <div
              className={`flex w-full items-center flex-col duration-300 overflow-x-hidden overflow-y-visible bg-no-repeat bg-cover bg-indigo-50`}
            >
              <AnalyticsBlock />
              <Header scrolled={scrolled} mini noLang />
              <div className="flex flex-col w-[70%] mt-[95px]">
                <PrivacyPolicy />
              </div>
            </div>
          </IntlProvider>
        </CookiesContext.Provider>
      </LocaleContext.Provider>
    );
  } else if (urlPath === "terms") {
    return (
      <LocaleContext.Provider
        value={{
          locale,
          setLocale,
        }}
      >
        <CookiesContext.Provider value={{ cookiesData, setCookiesData }}>
          <IntlProvider
            messages={messages[locale]}
            locale={locale}
            defaultLocale={LOCALES.ENGLISH}
          >
            <div
              className={`flex w-full items-center flex-col duration-300 overflow-x-hidden overflow-y-visible bg-no-repeat bg-cover bg-indigo-50`}
            >
              <AnalyticsBlock />
              <Header scrolled={scrolled} mini noLang />
              <div className="flex flex-col w-[70%] mt-[95px]">
                <Terms />
              </div>
            </div>
          </IntlProvider>
        </CookiesContext.Provider>
      </LocaleContext.Provider>
    );
  } else {
    return (
      <LocaleContext.Provider
        value={{
          locale,
          setLocale,
        }}
      >
        <CookiesContext.Provider value={{ cookiesData, setCookiesData }}>
          <IntlProvider
            messages={messages[locale]}
            locale={locale}
            defaultLocale={LOCALES.ENGLISH}
          >
            <main
              id="scroll-flag"
              className={`flex flex-col duration-300 bg-no-repeat bg-cover ${
                scrolled ? "bg-indigo-500" : "bg-indigo-200"
              }`}
            >
              <AnalyticsBlock />
              <Toast
                show={showToast}
                setShow={setShowToast}
                title={titleToast}
                message={messageToast}
                type={typeToast}
              />
              <ScrollToTopOnReload setLoading={setLoading} />
              <Header
                scrolled={scrolled}
                setShowToast={setShowToast}
                setTitleToast={setTitleToast}
                setMessageToast={setMessageToast}
                setTypeToast={setTypeToast}
                successSended={successSended}
                setSuccessSended={setSuccessSended}
              />
              <div
                className="scroll-phone overflow-x-hidden"
                ref={scrollPhoneContainer}
              >
                <Hero
                  scrolled={scrolled}
                  isPhoneHero={isPhoneHero}
                  setIsPhoneHero={setIsPhoneHero}
                  setShowToast={setShowToast}
                  setTitleToast={setTitleToast}
                  setMessageToast={setMessageToast}
                  setTypeToast={setTypeToast}
                  successSended={successSended}
                  setSuccessSended={setSuccessSended}
                />
                <Actions
                  isPhoneHero={isPhoneHero}
                  setIsPhoneHero={setIsPhoneHero}
                  sliderRef={slider}
                />
                <PhoneHero
                  containerRef={scrollPhoneContainer}
                  sliderRef={slider}
                />
              </div>

              {/*<PromoVideo />*/}
              <Comparison />
              <Advantages />
              <Stats />
              <HowItWorks />
              <Sell />
              <CTA />
              <Features
                setShowToast={setShowToast}
                setTitleToast={setTitleToast}
                setMessageToast={setMessageToast}
                setTypeToast={setTypeToast}
                successSended={successSended}
                setSuccessSended={setSuccessSended}
              />
              <Security />
              <ActionCTA
                setShowToast={setShowToast}
                setTitleToast={setTitleToast}
                setMessageToast={setMessageToast}
                setTypeToast={setTypeToast}
                successSended={successSended}
                setSuccessSended={setSuccessSended}
              />
              <Products />
              {/*<Pricing 
                    setShowToast={setShowToast} 
                    setTitleToast={setTitleToast}
                    setMessageToast={setMessageToast}
                    setTypeToast={setTypeToast} 
                    successSended={successSended}
          setSuccessSended={setSuccessSended}
                />*/}
              <FAQ />
              <Contact
                setShowToast={setShowToast}
                setTitleToast={setTitleToast}
                setMessageToast={setMessageToast}
                setTypeToast={setTypeToast}
              />
              <Footer />

              {/*{showBanner && <CookiesBanner setShowBanner={setShowBanner} />}*/}
            </main>
          </IntlProvider>
        </CookiesContext.Provider>
      </LocaleContext.Provider>
    );
  }
}

export default App;
