/* eslint-disable jsx-a11y/alt-text */
import Parallax from "parallax-js";
import { useEffect, useRef } from "react";
import { RoughNotation, RoughNotationGroup } from "react-rough-notation";
import Wave from "react-wavify";
import shape4 from "../assets/shape-4.png";
import { FormattedMessage } from "react-intl";

export default function Stats() {
  const scene = useRef();

  useEffect(() => {
    new Parallax(scene.current);
  }, []);

  return (
    <div className="flex flex-col overflow-x-hidden">
      <Wave
        fill="#6366f1"
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 4,
        }}
        style={{
          position: "relative",
          top: "-22px",
          backgroundColor: "#f3f4f6",
        }}
      />
      <div className="pt-12 sm:pt-16 relative top-[-24px] bg-indigo-500">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="wow fadeIn text-2xl font-bold tracking-tight text-white sm:text-4xl">
              <FormattedMessage id="winWin" />
            </h2>
            {/* <p className="mt-3 text-xl text-gray-500 sm:mt-4">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus repellat laudantium.
            </p> */}
          </div>
        </div>
        <div className="mt-10 pb-12 sm:pb-16 relative">
          <div className="absolute">
            <div
              data-relative-input="true"
              ref={scene}
              className="w-full h-full"
            >
              <img
                data-depth="0.1"
                src={shape4}
                style={{
                  marginTop: "-12vh",
                  marginLeft: "85vw",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          {/* <div className="absolute h-0 md:h-3/5 bottom-0 z-0 bg-white w-full" /> */}
          <div className="relative">
            <div className="absolute inset-0 b-0 h-1/2" />
            <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-full">
                <dl className="rounded-lg sm:grid sm:grid-cols-4 gap-4">
                  <RoughNotationGroup show={true}>
                    <div className="mb-3 md:mb-0 flex flex-col border-b border-gray-100 p-4 lg:p-6 sm:border-0 sm:border-r h-36 bg-white shadow-lg rounded-md">
                      <dt className="order-2 lg:mt-2 text-2xl font-normal leading-8 text-black">
                        <FormattedMessage id="upTo" />{" "}
                        <RoughNotation
                          type="highlight"
                          order="1"
                          color="#fef08a"
                        >
                          <FormattedMessage id="15min" />
                        </RoughNotation>{" "}
                        <FormattedMessage id="savedPerTable" />
                      </dt>
                    </div>
                    <div className="mb-3 md:mb-0 flex flex-col border-t border-b border-gray-100 p-6 sm:border-0 sm:border-l sm:border-r bg-white shadow-lg h-36 rounded-md">
                      <dt className="order-2 mt-2 text-2xl font-normal leading-8 text-black">
                        <RoughNotation type="circle" order="2" color="#4338ca">
                          +40%
                        </RoughNotation>
                        <br />
                        <FormattedMessage id="moreTips" />
                      </dt>
                    </div>
                    <div className="mb-3 md:mb-0 flex flex-col border-t border-b border-gray-100 p-6 sm:border-0 sm:border-l sm:border-r bg-white shadow-lg h-36 rounded-md">
                      <dt className="order-2 mt-2 text-2xl font-normal leading-8 text-black">
                        <RoughNotation type="box" order="3" color="#4338ca">
                          +12%
                        </RoughNotation>{" "}
                        <br />
                        <FormattedMessage id="averageBasket" />
                      </dt>
                    </div>
                    <div className="mb-3 md:mb-0 flex flex-col border-t border-gray-100 p-6 sm:border-0 sm:border-l bg-white shadow-lg h-36 rounded-md">
                      <dt className="order-2 mt-2 text-2xl font-normal leading-8 text-black">
                        <RoughNotation
                          type="underline"
                          order="4"
                          color="#4338ca"
                        >
                          +60%
                        </RoughNotation>{" "}
                        <FormattedMessage id="adoptionRate" />
                      </dt>
                    </div>
                  </RoughNotationGroup>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
