import { useContext } from "react";
import { CookiesContext } from "../../../context";
import { changeCookieStep, closeCookie, toogleAllService } from "../actions";
import baseCookie from "../../../assets/cookies/baseCookie.png";

export const BaseBanner = () => {
    const { cookiesData, setCookiesData } = useContext(CookiesContext);

    return (
        <>
            <button
                onClick={() => {
                    toogleAllService({
                        cookiesData,
                        setCookiesData,
                        value: true,
                    });
                    closeCookie({ setCookiesData });
                }}
                className="bg-white rounded-2xl shadow-[0_2px_4px_rgba(0,0,0,0.3)] w-auto px-1.5 py-1.5 text-xs absolute z-[100] right-0 -top-10"
            >
                <span className="text-gray-900">Continue without consent </span>
            </button>
            <div className="text-gray-800 px-5 pt-5 overflow-hidden rounded-t-lg">
                <div className="mb-2.5 pt-1">
                    <div className="relative float-right ml-2.5 mb-2.5 z-50 mt-[-15px] mr-[-15px]">
                        <img
                            src={baseCookie}
                            width="170"
                            alt=""
                            loading="lazy"
                            className="z-50 relative max-w-[140px] max-h-[80px] object-contain"
                        />
                    </div>
                    <div
                        role="heading"
                        aria-level={1}
                        className="flex flex-col text-2xl"
                    >
                        <small className="text-black">Hi there!</small>
                        <span className="text-black font-bold">
                            We&apos;re the cookies
                        </span>
                    </div>
                </div>
                <div className="text-sm mb-2.5">
                    We wanted to make sure you liked this site before saying hi.
                    Are we okay to join you on your journey here?
                </div>
                <div className="mt-1.5 mb-3">
                    <a
                        target="_blank"
                        href="/privacy-policy"
                        rel="noopener noreferrer"
                        className="text-xs underline text-gray-700 lg:hover:text-gray-800"
                    >
                        Your privacy is our priority. Discover how.
                    </a>
                </div>
            </div>
            <div className="flex items-stretch border-t border-t-gray-300">
                <button
                    onClick={() => {
                        changeCookieStep({ setCookiesData, step: 2 });
                    }}
                    className="flex-1 px-3 py-2 text-gray-800 border-r border-r-gray-300"
                >
                    I want to choose
                </button>
                <button
                    className="flex-1 px-3 py-2 text-primary font-bold"
                    onClick={() => {
                        closeCookie({ setCookiesData });
                    }}
                >
                    OK!
                </button>
            </div>
        </>
    );
};
