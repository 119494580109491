import { useContext } from "react";
import { ToggleBlock } from "./ToggleBlock";
import { CookiesContext } from "../../../context";
import { changeCookieStep, closeCookie, toogleAllService } from "../actions";
import chooseCookie from "../../../assets/cookies/chooseCookie.png";

export const ChooseBanner = () => {
    const { cookiesData, setCookiesData } = useContext(CookiesContext);

    return (
        <>
            <div className="relative px-5">
                <div className="z-50 absolute top-0 left-0 flex items-center justify-center w-full h-[108px] overflow-hidden">
                    <img
                        src={chooseCookie}
                        width="90"
                        alt=""
                        loading="lazy"
                        className="z-50 relative block object-contain max-w-[90px] max-h-[90px]"
                    />
                </div>
                <div className="relative -my-5 py-5 pb-2.5 pt-28 overflow-y-auto overflow-x-hidden">
                    <div
                        role="heading"
                        aria-level={1}
                        className="flex flex-col text-2xl text-center mb-2.5"
                    >
                        <small className="text-black font-bold">
                            Meet our cookies!
                        </small>
                        <span className="text-black text-base">
                            Light and absolutely harmless
                        </span>
                    </div>
                    <div className="text-sm text-gray-800 mb-2.5">
                        This site employs cookies to gauge our audience size,
                        enhance our connection with you, and occasionally
                        provide high-quality content and advertisements. Here,
                        you can choose which cookies you allow to stay.
                    </div>
                    <ToggleBlock />
                </div>
            </div>
            <div className="z-50 flex items-stretch border-t border-t-gray-300">
                <button
                    onClick={() => {
                        changeCookieStep({ setCookiesData, step: 1 });
                    }}
                    className="flex-1 px-3 py-2 text-gray-800 border-r border-r-gray-300"
                >
                    Back
                </button>
                <button
                    onClick={() => {
                        toogleAllService({
                            cookiesData,
                            setCookiesData,
                            value: true,
                        });
                        setTimeout(() => {
                            closeCookie({ setCookiesData });
                        }, 250);
                    }}
                    className="flex-1 px-3 py-2 text-primary border-r border-r-gray-300 font-bold"
                >
                    Accept all
                </button>
                <button
                    onClick={() => {
                        closeCookie({ setCookiesData });
                        setTimeout(() => {
                            changeCookieStep({ setCookiesData, step: 1 });
                        }, 250);
                    }}
                    className="flex-1 px-3 py-2 text-gray-800"
                >
                    Done
                </button>
            </div>
        </>
    );
};
