/* eslint-disable jsx-a11y/alt-text */

import { useEffect, useRef, useState } from "react";

export default function AdvantagesBlock({
    elem,
    index,
    handleActiveClick,
    isActive,
}) {
    const [height, setHeight] = useState();
    const blockRef = useRef();

    useEffect(() => {
        setHeight(
            window.innerWidth < 720
                ? "auto"
                : isActive
                ? calcActiveHeight()
                : "64px"
        );
    }, [isActive]);

    const calcActiveHeight = () => {
        const currH = blockRef.current.scrollHeight;
        return `${currH}px`;
    };

    return (
        <li
            key={elem.title}
            ref={blockRef}
            className="flex flex-col group cursor-pointer overflow-hidden bg-white px-4 py-4 shadow rounded-md sm:px-6 duration-100 md:md:hover:bg-indigo-600"
            style={{
                height: height || "auto",
                transition: "0.3s ease-in",
            }}
            onClick={() => {
                calcActiveHeight();
                handleActiveClick(index);
            }}
            onLoad={() => {
                setHeight(
                    window.innerWidth < 720
                        ? "auto"
                        : isActive
                        ? "auto"
                        : "64px"
                );
            }}
        >
            <p className="text-indigo-600 md:group-hover:text-white">
                {elem.title}
            </p>
            <p className="mt-3 text-base text-gray-600 md:group-hover:text-white">
                {elem.decr}
            </p>
            <img
                style={{
                    transition: "ease-in 0.3s",
                }}
                className="wow fadeIn md:hidden mt-4 md:mt-0 w-full max-h-[500px] object-contain"
                src={elem.img}
                alt=""
            />
        </li>
    );
}
