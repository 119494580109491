/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import bg1 from "../assets/bg1.jpg";
import bg2 from "../assets/bg2.jpg";
import bg3 from "../assets/bg3.jpg";
import bg4 from "../assets/qrcode.png";
import bg5 from "../assets/bg5.jpg";
import bg6 from "../assets/bg4.jpg";

import browserBlock from "../assets/browserBlock.png";

import group1 from "../assets/Group 1.png";
import group2 from "../assets/Group 2.png";

import shape5 from "../assets/shape-5.png";
import Parallax from "parallax-js";
import { useEffect, useRef, useState } from "react";
import { RoughNotation } from "react-rough-notation";
import { FormattedMessage } from "react-intl";

const data = [
  {
    title: <FormattedMessage id="qrCodes" />,
    decr: <FormattedMessage id="downloadTheQR" />,
    id: 1,
  },
  {
    title: <FormattedMessage id="noApp" />,
    decr: <FormattedMessage id="webBased" />,
    id: 2,
  },
  {
    title: <FormattedMessage id="realTimeData" />,
    decr: <FormattedMessage id="changesImmediately" />,
    id: 3,
  },
  {
    title: <FormattedMessage id="insightsAndAnalytics" />,
    decr: <FormattedMessage id="integratedReportingSystem" />,
    id: 4,
  },
];

export default function HowItWorks({ isPhoneHero, setIsPhoneHero }) {
  const [active, setActive] = useState(0);
  const scene = useRef();
  const [direction, setDirection] = useState("up");
  let oldScrollY = 0;

  useEffect(() => {
    new Parallax(scene.current);
  }, []);

  const handleActiveClick = (index) => {
    setActive(index);
  };

  const controlDirection = () => {
    if (window.scrollY > oldScrollY) {
      const phantomSteps = document.querySelector(".phantom-steps-hiw");
      if (phantomSteps.getBoundingClientRect().top <= 65) {
        phantomSteps.querySelectorAll(".step-hiw").forEach((step, i) => {
          if (
            step.getBoundingClientRect().top <= 65 &&
            step.getBoundingClientRect().top > 0
          ) {
            if (i > data.length - 1) {
              setActive(data.length - 1);
            } else {
              setActive(i);
            }
          }
        });
      }
      setDirection("down");
    } else {
      const phantomSteps = document.querySelector(".phantom-steps-hiw");
      if (phantomSteps.getBoundingClientRect().top <= 65) {
        phantomSteps.querySelectorAll(".step-hiw").forEach((step, i) => {
          if (
            step.getBoundingClientRect().top >= 65 &&
            step.getBoundingClientRect().top < 130 &&
            i !== 0
          ) {
            if (i > data.length - 1) {
              setActive(data.length - 1);
            } else {
              setActive(i);
            }
          } else if (i === 0 && step.getBoundingClientRect().top > -30) {
            setActive(0);
          }
        });
      }
      setDirection("up");
    }
    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    if (window.innerWidth >= 912) {
      window.addEventListener("scroll", controlDirection);
      return () => {
        window.removeEventListener("scroll", controlDirection);
      };
    }
  }, []);

  return (
    <div className="relative min-h-screen">
      <div className="w-full h-full md:absolute md:top-0">
        <main className="relative md:sticky md:top-[105px] w-full z-100">
          <div className="w-full h-full absolute z-0">
            <div
              data-relative-input="true"
              ref={scene}
              className="w-full h-full"
            >
              <img
                data-depth="0.1"
                src={shape5}
                style={{ objectFit: "contain" }}
              />
            </div>
          </div>
          <div className="px-8 md:px-24 lg:px-44">
            <h1 className="px-0 md:px-9 text-3xl font-bold text-white">
              <FormattedMessage id="howDoesIt" />{" "}
              <RoughNotation
                type="underline"
                order="1"
                color="#fef08a"
                show={true}
              >
                <FormattedMessage id="work" />
              </RoughNotation>
            </h1>
          </div>
          <div
            className="flex flex-col md:flex-row py-6 md:py-24 px-8 md:px-24 lg:px-44 sm:pb-12 md:pb-24 relative"
            style={{ top: "-20px" }}
          >
            <div className="w-[100%] md:w-[50%] px-0 md:px-9">
              <ul className="space-y-3 w-full text-2xl">
                {data.map((elem, index) => {
                  return (
                    <li
                      key={'how-it-works-' + elem.id}
                      className={`flex flex-col cursor-pointer overflow-hidden px-4 py-4 sm:px-6 duration-300 border-l-2 ${
                        window.innerWidth < 720 || !(index === active)
                          ? "border-indigo-300"
                          : "border-white"
                      }`}
                      style={{
                        height:
                          window.innerWidth < 720
                            ? "auto"
                            : index === active
                            ? "auto" // TODO: change to calculate height
                            : "64px",
                        transition: "0.3s ease-in",
                      }}
                      onClick={() => handleActiveClick(index)}
                    >
                      <p className="text-white whitespace-nowrap">
                        {elem.title}
                      </p>
                      <p className="mt-3 text-base text-indigo-200">
                        {elem.decr}
                      </p>
                      <div className="w-full flex items-center md:block justify-center mt-4 md:mt-0">
                        <div className="w-[60%] md:w-[40%] md:hidden ml-6 mt-4 ">
                          {index === 0 && (
                            <div className="grid grid-flow-col grid-rows-2 grid-cols-3 gap-4 relative">
                              <div className="transform scale-110 -rotate-6">
                                <img src={bg1} alt="" />
                              </div>
                              <div className="col-start-3 transform scale-100 rotate-6 translate-x-1 translate-y-4">
                                <img src={bg2} alt="" />
                              </div>
                              <div className="transform scale-150 translate-y-5">
                                <img src={bg3} alt="" />
                              </div>
                              <div className="transform translate-x-1 translate-y-6">
                                <img src={bg4} alt="" />
                              </div>
                              <div className="row-start-1 scale-90 col-start-2 col-span-2 transform translate-x-3 translate-y-1">
                                <img src={bg5} alt="" />
                              </div>
                              <div className="absolute transform translate-y-24 w-20 bottom-[40%] -right-[10%]">
                                <img src={bg6} alt="" />
                              </div>
                            </div>
                          )}
                          {index === 1 && (
                            <div className="w-full flex items-center justify-center">
                              <div className="ml-10 w-[75%]  xl:w-[55%]">
                                <img src={browserBlock} alt="" />
                              </div>
                            </div>
                          )}
                          {index === 2 && (
                            <div className="w-full flex items-center justify-center">
                              <div className="flex items-center justify-center w-[80%] xl:w-[60%]">
                                <img src={group1} alt="" />
                              </div>
                            </div>
                          )}
                          {index === 3 && (
                            <div className="w-full flex items-center justify-center">
                              <div className="flex items-center justify-center w-[80%] xl:w-[60%]">
                                <img src={group2} alt="" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              className={`${
                active !== 0 ? "md:w-[60%]" : "md:w-[40%]"
              } hidden md:block`}
            >
              {active === 0 && (
                <div className="grid grid-flow-col grid-rows-2 grid-cols-3 gap-8 relative">
                  <div className="transform scale-70 -rotate-6 rounded-md overflow-hidden">
                    <img src={bg1} alt="" className="rounded-md" />
                  </div>
                  <div className="col-start-3 transform scale-15 rotate-6 translate-x-2 translate-y-15 rounded-md overflow-hidden">
                    <img src={bg2} alt="" className="rounded-md" />
                  </div>
                  <div className="transform scale-150 translate-y-11 rounded-md overflow-hidden">
                    <img src={bg3} alt="" className="rounded-md" />
                  </div>
                  <div className="transform translate-y-24 rounded-md overflow-hidden ">
                    <img src={bg4} alt="" className="rounded-md" />
                  </div>
                  <div className="row-start-1 col-start-2 col-span-2 transform translate-x-20 translate-y-4 rounded-md overflow-hidden">
                    <img src={bg5} alt="" className="rounded-md" />
                  </div>
                  <div className="absolute transform translate-y-24 w-40 bottom-[10%] -right-[20%] rounded-md overflow-hidden">
                    <img src={bg6} alt="" className="rounded-md" />
                  </div>
                </div>
              )}
              {active === 1 && (
                <div className="w-full flex items-center justify-center">
                  <div className="ml-10 w-[75%]  xl:w-[55%]">
                    <img src={browserBlock} alt="" />
                  </div>
                </div>
              )}
              {active === 2 && (
                <div className="w-full flex items-center justify-center">
                  <div className="flex items-center justify-center w-[80%] xl:w-[60%]">
                    <img src={group1} alt="" />
                  </div>
                </div>
              )}
              {active === 3 && (
                <div className="w-full flex items-center justify-center">
                  <div className="flex items-center justify-center w-[80%] xl:w-[60%]">
                    <img src={group2} alt="" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>

      <div className="phantom-steps-hiw hidden md:block">
        <div className="w-[50vw] h-[50vh] step-hiw"></div>
        <div className="w-[50vw] h-[50vh] step-hiw"></div>
        <div className="w-[50vw] h-[50vh] step-hiw"></div>
        <div className="w-[50vw] h-[50vh] step-hiw"></div>

        <div className="w-[50vw] h-[50vh] step-hiw"></div>
      </div>

      {/* <Wave
                fill="#6366f1"
                paused={false}
                options={{
                    height: 20,
                    amplitude: 20,
                    speed: 0.15,
                    points: 4,
                }}
                style={{
                    position: "relative",
                    top: "10px",
                    transform: "rotate(180deg)",
                    backgroundColor: "#f3f4f6",
                }}
            /> */}
    </div>
  );
}
