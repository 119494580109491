import React from "react";

export const Spinner = ({ color = "bg-white" }) => {
  return (
    <div className={`flex items-center justify-center py-1`}>
      <div className="flex space-x-2 animate-pulse">
        <div className={`w-3 h-3 ${color} rounded-full`}></div>
        <div className={`w-3 h-3 ${color} rounded-full`}></div>
        <div className={`w-3 h-3 ${color} rounded-full`}></div>
      </div>
    </div>
  );
};
