/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../logo.svg";
import {
  AiOutlineControl,
  AiOutlineMail,
  AiOutlineMobile,
} from "react-icons/ai";
import { FeedbackFormModal } from "./FeedbackFormModal";
import { ADMIN_LINK } from "../constants";
import { trackEvent } from "../helpers";
import { ChangeLanguage } from "./ChangeLanguage";
import { FormattedMessage } from "react-intl";

const solutions = [
  {
    name: <FormattedMessage id="about" />,
    href: "#comparison",
    icon: AiOutlineMobile,
  },
  {
    name: <FormattedMessage id="features" />,
    href: "#features",
    icon: AiOutlineControl,
  },
  //{
  //    name: "Pricing",
  //    href: "#pricing",
  //    icon: AiOutlineCreditCard,
  //},
  {
    name: <FormattedMessage id="contact" />,
    href: "#contact",
    icon: AiOutlineMail,
  },
];

export default function Header({
  scrolled,
  setShowToast,
  setMessageToast,
  setTitleToast,
  setTypeToast,
  successSended,
  setSuccessSended,
  mini = false,
  noLang = false,
}) {
  const [showFeedback, setShowFeedback] = useState(false);
  return (
    <Popover
      className="fixed top-0 z-30 w-full drop-shadow-sm transition-all"
      style={{
        backgroundColor: `${
          scrolled ? "rgba(255,255,255,1)" : "rgba(255,255,255,0)"
        }`,
      }}
    >
      <FeedbackFormModal
        open={showFeedback}
        setOpen={setShowFeedback}
        setShowToast={setShowToast}
        setTitleToast={setTitleToast}
        setMessageToast={setMessageToast}
        setTypeToast={setTypeToast}
        successSended={successSended}
        setSuccessSended={setSuccessSended}
      />
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div
          className={`flex items-center justify-between ${
            scrolled ? "py-3" : "py-6"
          } md:justify-start md:space-x-10 transition-all`}
        >
          <div className="flex justify-start">
            <a href="/">
              <span className="sr-only">Your Company</span>
              <img
                className={`h-10 w-auto sm:h-10 transition-all`}
                src={logo}
                alt=""
              />
            </a>
          </div>

          {!mini && (
            <div className="flex items-center">
              {!noLang && (
                <div className="mr-3 md:hidden">
                  <ChangeLanguage mini />
                </div>
              )}
              <div className="-my-2 -mr-2 md:hidden">
                <Popover.Button className="inline-flex items-center border border-black/5 justify-center rounded-md bg-white p-2 text-gray-400 md:hover:bg-gray-100 md:hover:text-gray-500 md:foocus:outline-none md:foocus:ring-2 md:foocus:ring-inset md:foocus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <Popover.Group as="nav" className="hidden space-x-10 md:flex">
                <div
                  onClick={() => {
                    document.getElementById("comparison").scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  className={`nav-item cursor-pointer text-lg font-medium ${
                    scrolled ? "text-gray-500" : "text-gray-900"
                  } md:hover:${scrolled ? "text-gray-900" : "text-indigo-600"}`}
                >
                  <FormattedMessage id="about" />
                </div>
                <div
                  onClick={() => {
                    document.getElementById("features").scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  className={`nav-item cursor-pointer text-lg font-medium ${
                    scrolled ? "text-gray-500" : "text-gray-900"
                  } md:hover:${scrolled ? "text-gray-900" : "text-indigo-600"}`}
                >
                  <FormattedMessage id="features" />
                </div>

                {/*<div
                                    onClick={() => {
                                        document
                                            .getElementById("pricing")
                                            .scrollIntoView({
                                                behavior: "smooth",
                                            });
                                    }}
                                    className={`nav-item cursor-pointer text-lg font-medium ${
                                        scrolled
                                            ? "text-gray-500"
                                            : "text-gray-900"
                                    } md:hover:${
                                        scrolled
                                            ? "text-gray-900"
                                            : "text-indigo-600"
                                    }`}
                                >
                                    Pricing
                                </div>*/}
                <div
                  onClick={() => {
                    document.getElementById("contact").scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  className={`nav-item cursor-pointer text-lg font-medium ${
                    scrolled ? "text-gray-500" : "text-gray-900"
                  } md:hover:${scrolled ? "text-gray-900" : "text-indigo-600"}`}
                >
                  <FormattedMessage id="contact" />
                </div>
              </Popover.Group>
            </div>
          )}

          <div className="hidden items-stretch justify-end md:flex md:flex-1 lg:w-0">
            {!noLang && <ChangeLanguage />}
            <a
              href={ADMIN_LINK}
              target="_blank"
              className={`inline-flex ml-6 items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-600 shadow-sm hover:bg-indigo-100`}
              rel="noreferrer"
            >
              <FormattedMessage id="login" />
            </a>
            {mini ? (
              <a
                href="/"
                className={`ml-6 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 transition-all`}
              >
                <FormattedMessage id="home" />
              </a>
            ) : (
              <button
                onClick={() => {
                  trackEvent("trackCustom", "ClickFreeTrial", {
                    pagePlace: "header",
                  });
                  setShowFeedback(true);
                }}
                className={`ml-6 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 transition-all`}
              >
                <FormattedMessage id="freeTrial" />
              </button>
            )}
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden"
        >
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src={logo} alt="Your Company" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 md:hover:bg-gray-100 md:hover:text-gray-500 md:foocus:outline-none md:foocus:ring-2 md:foocus:ring-inset md:foocus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {solutions.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 flex items-center rounded-md p-3 md:hover:bg-gray-50"
                    >
                      <item.icon
                        className="h-6 w-6 flex-shrink-0 text-indigo-600"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {item.name}
                      </span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            {/* <div className="space-y-6 py-6 px-5">
                            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                <a
                                    href="#"
                                    className="text-base font-medium text-gray-900 md:hover:text-gray-700"
                                >
                                    Pricing
                                </a>

                                <a
                                    href="#"
                                    className="text-base font-medium text-gray-900 md:hover:text-gray-700"
                                >
                                    Docs
                                </a>
                                {resources.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="text-base font-medium text-gray-900 md:hover:text-gray-700"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <div>
                                <a
                                    href="#"
                                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700"
                                >
                                    Sign up
                                </a>
                                <p className="mt-6 text-center text-base font-medium text-gray-500">
                                    Existing customer?{" "}
                                    <a
                                        href="#"
                                        className="text-indigo-600 md:hover:text-indigo-500"
                                    >
                                        Sign in
                                    </a>
                                </p>
                            </div>
                        </div> */}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
