import React from "react";

export const Terms = () => {
    return (
        <div className="text-indigo-500">
            <h1
                className="code-line text-3xl font-medium text-indigo-600"
                data-line-start={0}
                data-line-end={1}
            >
                Terms of use of DigitalMenu application services for parents
            </h1>
            <p
                className="has-line-data my-3"
                data-line-start={2}
                data-line-end={3}
            >
                Last updated: May 15, 2023
            </p>
            <h2
                className="code-line text-2xl font-medium text-indigo-600 mb-1"
                data-line-start={3}
                data-line-end={4}
            >
                1. Introductory provisions
            </h2>
            <p
                className="has-line-data mb-2"
                data-line-start={5}
                data-line-end={6}
            >
                These Terms of Use of DigitalMenu Application services
                (hereinafter “Terms“) are issued by DigitalMenu.ai KFT,
                Identification Number: 18-09-115586, registered office 9752
                Kenéz, Kossuth Lajos utca 6., (hereinafter “DigitalMenu,” “we,”
                or “us“, “Operator“, “Administrator” “services,” “service,”
                “application,” “website,”).
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={7}
                data-line-end={8}
            >
                DigitalMenu.ai offers to improve the experience of customers who
                frequent your Establishments by providing the following services
                to you (“ DigitalMenu.ai Services“).
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={9}
                data-line-end={10}
            >
                By displaying our QR codes in your Establishment, you allow your
                customers using the Solution (“DigitalMenu Visitors”) to benefit
                from all or part of the Solution:
            </p>
            <ul className="list-disc ml-8">
                <li
                    className="has-line-data mb-3"
                    data-line-start={11}
                    data-line-end={13}
                >
                    “Online Menu” service: access your menu of items via QR code
                    places on a table or online platform
                </li>
            </ul>
            <p
                className="has-line-data mb-2"
                data-line-start={13}
                data-line-end={14}
            >
                <strong>What DigitalMenu.ai offer</strong>
            </p>
            <ul className="list-disc ml-8">
                <li
                    className="has-line-data mb-2"
                    data-line-start={14}
                    data-line-end={15}
                >
                    DigitalMenu.ai offers the DigitalMenu Services on a
                    non-exclusive basis.
                </li>
                <li
                    className="has-line-data mb-3"
                    data-line-start={15}
                    data-line-end={17}
                >
                    With the exception of maintenance periods, the DigitalMenu
                    Services are accessible 24 hours a day, 7 days a week. In
                    case of malfunction or interruption of the DigitalMenu
                    Services or of Anomalies and upon notification sent by email
                    to{" "}
                    <a
                        target="_blank"
                        className="text-indigo-600 underline"
                        rel="noreferrer"
                        href="mailto:support@digital-menu.ai"
                    >
                        support@digital-menu.ai
                    </a>
                    , we will make our best efforts to restore the DigitalMenu
                    Services as soon as possible.
                </li>
            </ul>
            <p
                className="has-line-data mb-2"
                data-line-start={17}
                data-line-end={18}
            >
                <strong>We will take care of:</strong>
            </p>
            <ul className="list-disc ml-8">
                <li
                    className="has-line-data mb-2"
                    data-line-start={18}
                    data-line-end={19}
                >
                    the integration of the Solution to your point of sale
                    (“POS”) or to your aggregators and middleware/software
                </li>
                <li
                    className="has-line-data mb-3"
                    data-line-start={19}
                    data-line-end={21}
                >
                    the troubleshooting as well as upgrading and maintenance of
                    the Solution
                </li>
            </ul>
            <p
                className="has-line-data mb-2"
                data-line-start={21}
                data-line-end={22}
            >
                <strong>
                    DigitalMenu.ai also provides you with a back-office virtual
                    platform where you can:
                </strong>
            </p>
            <ul className="list-disc ml-8">
                <li
                    className="has-line-data mb-2"
                    data-line-start={22}
                    data-line-end={23}
                >
                    customize the interface that the Users access
                </li>
                <li
                    className="has-line-data mb-2"
                    data-line-start={23}
                    data-line-end={24}
                >
                    add, remove or modify your menu and offers to the visitors,
                </li>
                <li
                    className="has-line-data mb-2"
                    data-line-start={24}
                    data-line-end={25}
                >
                    access records of all Orders via the Solution,
                </li>
                <li
                    className="has-line-data mb-3"
                    data-line-start={25}
                    data-line-end={26}
                >
                    access analysis on the use and performance of the Solution
                </li>
            </ul>
            <p
                className="has-line-data mb-2"
                data-line-start={28}
                data-line-end={29}
            >
                Terms of Use and Privacy Policy are applicable to access and use
                of the website{" "}
                <a
                    target="_blank"
                    className="text-indigo-600 underline"
                    rel="noreferrer"
                    href="https://digital-menu.ai"
                >
                    https://digital-menu.ai
                </a>{" "}
                and/or the web application and all other products and services
                of DigitalMenu.ai.
            </p>
            <p
                className="has-line-data mb-3"
                data-line-start={30}
                data-line-end={31}
            >
                By downloading, using, registering on the Website and/or the
                Application, viewing, submitting content or using any other
                access and/or -using the service, the User (hereinafter referred
                to as “You,” “Your,” “Yours”, “Yourself”) agrees to these Terms
                and Policy set forth below. If you do not wish to agree to all
                of these Terms and Policy set forth in this document, please
                discontinue using our service.
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={33}
                data-line-end={34}
            >
                <strong>Changes to the Terms of Use</strong>
            </p>
            <p
                className="has-line-data mb-5"
                data-line-start={35}
                data-line-end={38}
            >
                The provisions of these Terms of Use and Privacy Policy may be
                modified in the future. Each time we make changes to this
                Privacy Policy and Terms of Use, we will publish the revised
                version on this page and indicate the date of the new revision.
                <br />
                Any changes to these Terms and Policy shall take effect
                immediately upon their publication. Your continued use of the
                Service after the changes to these Terms and Policy signifies
                your agreement to such changes. If you do not wish to agree to
                any changes to these Terms and Policy, please immediately
                discontinue the use of the Service.
                <br />
                These Terms and Policy shall also govern any updates or new
                versions of the Website or Application, which may replace and/or
                supplement the original Application or Website. If an update or
                new version is not accompanied by its own Terms and Policy,
                these Terms and Policy shall prevail.
            </p>
            <h2
                className="code-line text-2xl font-medium text-indigo-600 mb-1"
                data-line-start={39}
                data-line-end={40}
            >
                2. Definitions
            </h2>
            <p
                className="has-line-data mb-5"
                data-line-start={41}
                data-line-end={59}
            >
                <strong>Services of DigitalMenu.ai</strong> - refer to various
                services provided to you by DigitalMenu.ai, which are based on
                Internet technologies and offered through the website{" "}
                <a
                    target="_blank"
                    className="text-indigo-600 underline"
                    rel="noreferrer"
                    href="https://digital-menu.ai"
                >
                    https://digital-menu.ai
                </a>
                , or mobile application, as well as other ways (including new
                ones that will become possible due to future technological
                advancements). The services of DigitalMenu.ai include, among
                other things, such components of DigitalMenu.ai as a cloud
                service for order and menu management in bars, hotels and
                restaurants.
                <br />
                <strong>Terms</strong>: are these Terms of Use of DigitalMenu.ai
                Application services.
                <br />
                <strong>Contract</strong>: is a contract concluded between the
                Operator and the Partner, whose subject is primarily the
                provision of Services to the Partner by the Operator, and the
                Partner’s undertaking to pay the Operator the agreed
                remuneration, as well as the related rights and obligations of
                both Contracting Parties.
                <br />
                <strong>Account</strong>: refers to the user account containing
                information provided by the User to the Administration during
                the registration process on the website{" "}
                <a
                    target="_blank"
                    className="text-indigo-600 underline"
                    rel="noreferrer"
                    href="https://digital-menu.ai"
                >
                    https://digital-menu.ai
                </a>{" "}
                or while operating within the account. The DigitalMenu.ai
                account serves as the basis for the User to exercise their
                rights in DigitalMenu.ai.
                <br />
                <strong>Registration</strong>: is the Partner’s electronic
                registration in the DigitalMenu.ai Application, properly
                performed by the entry of at least the mandatory registration
                details, and their subsequent storage in the DigitalMenu.ai
                Application.
                <br />
                <strong>Login</strong>: is a unique identifier of the User used
                to designate and identify them on the website{" "}
                <a
                    target="_blank"
                    className="text-indigo-600 underline"
                    rel="noreferrer"
                    href="https://digital-menu.ai"
                >
                    https://digital-menu.ai
                </a>
                .
                <br />
                <strong>Password</strong>: is a set of characters used to
                confirm the User’s identity during the account login process
                (authentication).
                <br />
                <strong>User</strong>: is a natural or legal person or entity
                visiting the website{" "}
                <a
                    target="_blank"
                    className="text-indigo-600 underline"
                    rel="noreferrer"
                    href="https://digital-menu.ai"
                >
                    https://digital-menu.ai
                </a>{" "}
                or uses the DigitalMenu.ai Application, thereby concluding a
                Brokered Agreement with the Partner.
                <br />
                <strong>Data</strong>: means all data that you provide to us in
                connection with the use of DigitalMenu.ai Services.
                <br />
                <strong>Personal Data</strong>: data directly or indirectly
                related to a natural person (e.g., identifier, name,
                identification number, location data, online identifier) that
                may be shared between us in the course of this Agreement.
                <br />
                <strong>Brokered Agreement</strong>: is a purchase or other
                contract concluded between the Partner and the User via the
                DigitalMenu.ai Application in relation to Services.
                <br />
                <strong>Partner</strong>: is the operator of a restaurant or
                other business providing catering services, or another partner
                (natural or legal person engaged in business) whose Services are
                offered and sold via the DigitalMenu.ai Application on the basis
                of the Contract.
                <br />
                <strong>Establishment(s)</strong>: any type of venue open to the
                public in which you provide to the visitors food and/or beverage
                services (e.g. bar, festival, restaurant, food court, stadium,
                and similar).
                <br />
                <strong>Visitor</strong>: is an individual who has voluntarily
                expressed their intention to place an order at your
                establishment.
                <br />
                <strong>Fees</strong>: refers to any combination of or
                collectively Activation Fees, Service Fees, and/or Transaction
                Fees.
                <br />
                <strong>Price List</strong>: is the Operator’s Price List, which
                forms an Appendix to these Terms and an integral part of the
                Contract with the Partner.
                <br />
                <strong>Selling Price</strong>: is the price of the Partner’s
                Services (excluding VAT) purchased by the User on the basis of a
                Brokered Agreement as per the information recorded by the
                DigitalMenu.ai Application.
                <br />
                <strong>Payment Service Provider</strong> : means a secure
                payment service provider that can handle the processing of
                transactions.
            </p>
            <h2
                className="code-line text-2xl font-medium text-indigo-600 mb-2"
                data-line-start={60}
                data-line-end={61}
            >
                3. Conclusion and subject of the contract
            </h2>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={61}
                data-line-end={62}
            >
                3.1.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={62}
                data-line-end={63}
            >
                DigitalMenu.ai grants the Partner a non-exclusive license to use
                the DigitalMenu.ai Service, and the Partner and Visitor shall
                use the Service in accordance with the terms of the Agreement.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={64}
                data-line-end={65}
            >
                3.2.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={65}
                data-line-end={66}
            >
                The Partner’s Registration in the DigitalMenu.ai Application
                constitutes a binding offer by the Partner to conclude a
                Contract with the Operator. By sending the Registration, the
                Partner expresses their agreement with the current versions of
                the Terms and the Price List.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={67}
                data-line-end={68}
            >
                3.3.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={68}
                data-line-end={69}
            >
                If the Registration is approved, then the Operator sends the
                Partner a confirmation to the Partner’s e-mail address set forth
                in the Registration; the delivery of this confirmation to the
                Partner constitutes the conclusion of the Contract. The Contract
                is concluded for an indefinite period.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={70}
                data-line-end={71}
            >
                3.4.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={71}
                data-line-end={72}
            >
                On the basis of the Contract, the Operator undertakes to provide
                Services to the Partner, and the Partner undertakes to pay the
                Operator the remuneration properly and on time, as well as to
                fulfil the other obligations stipulated in the Contract.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={73}
                data-line-end={74}
            >
                3.5.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={74}
                data-line-end={75}
            >
                The Partner explicitly acknowledges, and agrees with, the fact
                that the Operator does not guarantee the Partner any number of
                Brokered Agreements.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={76}
                data-line-end={77}
            >
                3.6.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={77}
                data-line-end={78}
            >
                The Operator makes the DigitalMenu.ai Application, or a relevant
                part thereof, available to the Partner for the purpose of
                managing the Partner’s profile and their menu of Products,
                including their prices and other information. The Partner is
                responsible for including information regarding allergens in
                their menu in the DigitalMenu.ai Application. In addition to the
                designation of the Product and its price. The presentation can
                also contain a more detailed description of the Product, its
                properties, sizes and availability, and possibly also a
                photographic illustration of same. The Partner is responsible
                for the correctness, up-to-dateness and legal flawlessness of
                the information published in the DigitalMenu.ai Application. The
                Operator reserves the right to remove a Product from the
                Partner’s menu in the DigitalMenu.ai Application without
                notifying the Partner in advance.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={79}
                data-line-end={80}
            >
                3.7.
            </h5>
            <p
                className="has-line-data mb-5"
                data-line-start={80}
                data-line-end={81}
            >
                The Partner undertakes to provide the Operator with the
                necessary information relating to Brokered Agreements.
            </p>
            <h2
                className="code-line text-2xl font-medium text-indigo-600 mb-2"
                data-line-start={85}
                data-line-end={86}
            >
                4. Registration and account
            </h2>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={86}
                data-line-end={87}
            >
                4.1. How to Subscribe
            </h5>
            <p
                className="has-line-data mb-2"
                data-line-start={88}
                data-line-end={89}
            >
                To access and utilize services on the Website or Application,
                the Partner must register an account. Registration is carried
                out by completing the relevant information (electronic form) on
                the Website or Application and submitting an account
                registration request, which must be approved by the
                Administration.
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={90}
                data-line-end={91}
            >
                Account registration is considered confirmed by the
                Administration upon notifying the Partner of the creation of
                their account by sending a corresponding email to the contact
                details provided by the Partner during registration.
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={92}
                data-line-end={93}
            >
                Please note that certain services may not be available in
                specific jurisdictions or regions or for certain Partners. We
                reserve the right to change, modify, or impose additional
                restrictions at our discretion on your account at any time.
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={94}
                data-line-end={95}
            >
                Mandatory information for completion includes:
            </p>
            <ul className="list-disc ml-8 mb-3">
                <li
                    className="has-line-data mb-2"
                    data-line-start={95}
                    data-line-end={96}
                >
                    Name
                </li>
                <li
                    className="has-line-data mb-2"
                    data-line-start={96}
                    data-line-end={97}
                >
                    Email address (E-mail)
                </li>
                <li
                    className="has-line-data mb-2"
                    data-line-start={97}
                    data-line-end={98}
                >
                    Phone number
                </li>
                <li
                    className="has-line-data mb-2"
                    data-line-start={98}
                    data-line-end={99}
                >
                    Establishment name
                </li>
                <li
                    className="has-line-data mb-2"
                    data-line-start={99}
                    data-line-end={101}
                >
                    Password
                </li>
            </ul>
            <p
                className="has-line-data mb-2"
                data-line-start={101}
                data-line-end={102}
            >
                Creating more than one account for the same individual is not
                allowed. Partner accounts cannot be transferred to third
                parties.
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={103}
                data-line-end={104}
            >
                You are in charge of determining which users within your
                organization will have access to the Account.
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={105}
                data-line-end={106}
            >
                We will not be liable for any damages that may result from your
                failure to comply with this section.
            </p>
            <p
                className="has-line-data mb-3"
                data-line-start={107}
                data-line-end={108}
            >
                Your registration of an account will be deemed as your agreement
                to provide personal information for identity verification if
                necessary. Such information will be used for the purpose of
                verifying the identity of the Partner(s), detecting money
                laundering, terrorist financing, fraud, and other financial
                crimes through our service, or for other lawful purposes stated
                by us. We will collect, use, and disclose such information in
                accordance with our Privacy Policy. In addition to providing
                such information, you agree to allow us to retain this
                information for the period your account is active and for a
                period of five (5) years after the closure of your account, in
                accordance with global industry data retention standards. You
                also authorize us to conduct necessary investigations directly
                or through third parties to verify your identity or protect you
                and/or us from financial crimes, such as fraud. The information
                required for confirming your identity may include, among other
                things, contact information, phone number, government-issued
                identification, date of birth, and other information. By
                providing the necessary information, you confirm that it is true
                and accurate. After completing the registration for your
                DigitalMenu account, you may use various DigitalMenu services.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={109}
                data-line-end={110}
            >
                4.2. Submission of Partner’s Application for Connection to the
                DigitalMenu.ai Service
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={111}
                data-line-end={112}
            >
                To access services on the Website or Application, the Partner
                must submit a partnership application by activating the “Connect
                Establishment” feature and completing the corresponding form.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={113}
                data-line-end={114}
            >
                4.3. Consideration of Partner’s Application
            </h5>
            <p
                className="has-line-data mb-2"
                data-line-start={115}
                data-line-end={116}
            >
                Upon receipt of your application, we will review it, and our
                manager will contact you using the contact details provided by
                you in the relevant form to assist in the implementation of
                DigitalMenu.ai integration.
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={117}
                data-line-end={118}
            >
                The processing time for your application is not fixed and may
                take an indefinite amount of time necessary for us to conduct
                the appropriate analysis and evaluation of your establishment.
            </p>
            <p
                className="has-line-data mb-5"
                data-line-start={119}
                data-line-end={120}
            >
                Upon approval of the application and connection of the Partner
                to the DigitalMenu.ai services, the Partner is granted the
                status of a Seller.
            </p>
            <h2
                className="code-line text-2xl font-medium text-indigo-600 mb-2"
                data-line-start={121}
                data-line-end={122}
            >
                5. Account Security
            </h2>
            <p
                className="has-line-data mb-2"
                data-line-start={123}
                data-line-end={124}
            >
                You undertake not to disclose to any third parties the login and
                password provided or obtained during registration. You bear sole
                responsibility for all actions taken on your behalf, that is,
                using their your and password.
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={125}
                data-line-end={126}
            >
                You also agree to assume sole responsibility for taking
                necessary security measures to protect your account and personal
                information in DigitalMenu.ai Application.
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={127}
                data-line-end={128}
            >
                By creating an account in DigitalMenu.ai Application, you agree
                that:
            </p>
            <ul className="list-disc ml-8 mb-5">
                <li
                    className="has-line-data mb-2"
                    data-line-start={128}
                    data-line-end={129}
                >
                    you will immediately notify DigitalMenu.ai if you become
                    aware of any unauthorized use of your account and password
                    or any other security breach
                </li>
                <li
                    className="has-line-data mb-2"
                    data-line-start={129}
                    data-line-end={130}
                >
                    you will strictly comply with all DigitalMenu.ai mechanisms
                    and procedures related to security, authentication,
                    purchasing/trading, fund allocation, and withdrawal
                </li>
                <li
                    className="has-line-data"
                    data-line-start={130}
                    data-line-end={131}
                >
                    you will take appropriate steps to properly log out of your
                    account at the end of each session.
                </li>
            </ul>
            <h2
                className="code-line text-2xl font-medium text-indigo-600 mb-2"
                data-line-start={133}
                data-line-end={134}
            >
                6. Prohibited Use
            </h2>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={135}
                data-line-end={136}
            >
                6.1.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={136}
                data-line-end={137}
            >
                You agree not to upload, post, email, or otherwise transmit any
                materials that contain computer viruses or any other computer
                code, files, or programs designed to interrupt, harm, damage,
                destroy, or limit the functionality of any computer software,
                hardware, or equipment directly or indirectly associated with
                the Website or Application.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={138}
                data-line-end={139}
            >
                6.2.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={139}
                data-line-end={140}
            >
                You agree not to interfere with the servers or networks
                underlying or connected to the said Website or Application, nor
                violate any procedures, policies, or rules of networks connected
                to the Website and/or Application.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={141}
                data-line-end={142}
            >
                6.3.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={142}
                data-line-end={143}
            >
                You agree not to rent, sublicense, translate, merge, adapt, or
                modify the DigitalMenu.ai application or any related
                documentation, and to use the DigitalMenu.ai application solely
                for personal purposes (if you are a Visitor) or for internal
                business purposes (if you are an Establishment).
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={144}
                data-line-end={145}
            >
                6.4.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={145}
                data-line-end={146}
            >
                You agree not to permit any third party to copy, adapt, reverse
                engineer, decompile, disassemble, modify, or correct errors in
                the DigitalMenu.ai application, in whole or in part, engage in
                reverse engineering, or create derivative works based on the
                DigitalMenu.ai application and/or Website, or attempt to do any
                of the foregoing. The application and Website may contain
                certain third-party software, plugins, or application interfaces
                collectively referred to as “Third-Party Software,” which may
                require separate terms of agreement.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={147}
                data-line-end={148}
            >
                6.5.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={148}
                data-line-end={149}
            >
                You agree not to impersonate or attempt to impersonate
                DigitalMenu.ai, the DigitalMenu.ai service, a DigitalMenu.ai
                company employee, another user, or any other individual or legal
                entity (including, among others, using email addresses or
                aliases associated with any of the above).
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={150}
                data-line-end={151}
            >
                6.6.
            </h5>
            <p
                className="has-line-data mb-5"
                data-line-start={151}
                data-line-end={152}
            >
                You agree not to transmit or provide for the transmission of any
                advertising or marketing materials without our prior written
                consent.
            </p>
            <h2
                className="code-line text-2xl font-medium text-indigo-600 mb-2"
                data-line-start={153}
                data-line-end={154}
            >
                7. Operator’s renuneration and payment terms
            </h2>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={155}
                data-line-end={156}
            >
                7.1.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={156}
                data-line-end={157}
            >
                The Operator is entitled to remuneration for the provision of
                the Services.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={158}
                data-line-end={159}
            >
                7.2.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={159}
                data-line-end={160}
            >
                The Operator’s remuneration consists of a monthly fee from the
                Partner for the use of the DigitalMenu.ai Application service,
                which amount is stipulated in the Price List (hereinafter
                “Monthly Fee“).
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={161}
                data-line-end={162}
            >
                7.3.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={162}
                data-line-end={163}
            >
                The Monthly Fee is always charged for the month in advance,
                unless the Operator and the Partner agree otherwise. The
                Operator shall issue a tax document (invoice) to the Partner for
                a sum corresponding to the amount of the Monthly Fee, always on
                the day of subscription on the relevant month for which the
                Monthly Fee is paid; VAT at the rate as per the relevant legal
                regulations will be added to this amount. The due payment date
                of the invoice is 14 (fourteen) calendar days from the date of
                issue.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={164}
                data-line-end={165}
            >
                7.4.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={165}
                data-line-end={166}
            >
                The Contracting Parties can agree in writing on individual price
                and payment terms. In that case, the agreed individual terms
                take precedence over the Price List.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={167}
                data-line-end={168}
            >
                7.5.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={168}
                data-line-end={169}
            >
                The Operator is entitled to unilaterally amend the Price List.
                The Operator will notify the Partner of the amendment to the
                Price List before it comes into effect, including the date of
                effectiveness of the amendment, at least 14 (fourteen) calendar
                days before the amendment comes into effect. If the Partner does
                not agree with the amendment to the Price List, then you have
                the right to terminate the subscription no later than the time
                the new Price List comes into effect. In that case, the
                withdrawal from the Contract is effective the day that the new
                Price List comes into effect. If the Partner does not withdraw
                from the Contract as per the previous provisions, then they are
                bound by the new Price List from the first day of its
                effectiveness.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={170}
                data-line-end={171}
            >
                7.6.
            </h5>
            <p
                className="has-line-data mb-5"
                data-line-start={171}
                data-line-end={172}
            >
                In addition to the Monthly Fee, the Operator is entitled to
                stipulate and request other remuneration for activities
                performed by the Operator on the basis of or in connection with
                the Contract, primarily for services involving support for and
                maintenance of the DigitalMenu.ai Application, integration etc.
            </p>
            <h2
                className="code-line text-2xl font-medium text-indigo-600 mb-2"
                data-line-start={173}
                data-line-end={174}
            >
                8. Granting of licence
            </h2>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={175}
                data-line-end={176}
            >
                8.1.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={176}
                data-line-end={177}
            >
                The Operator provides the Partner with a non-exclusive
                authorisation to exercise the right to use the DigitalMenu.ai
                Application (hereinafter “Licence“), only for the purposes of
                the proper use of the Services, for the duration of the
                Contract.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={178}
                data-line-end={179}
            >
                8.2.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={179}
                data-line-end={180}
            >
                The remuneration for the provision of the Licence is included in
                the remuneration for the Services as per Article 7 of the Terms.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={181}
                data-line-end={182}
            >
                8.3.
            </h5>
            <p
                className="has-line-data mb-5"
                data-line-start={182}
                data-line-end={183}
            >
                Under no circumstances does the Partner have the right to grant
                any third party a sublicence, or transfer the Licence to any
                other party, on the basis of a Licence granted in this way. The
                Partner also does not have the right to copy the DigitalMenu.ai
                Application for the purpose of its distribution, distribute it,
                rent or lend it, or disclose it to third parties in any way.
            </p>
            <h2
                className="code-line text-2xl font-medium text-indigo-600 mb-2"
                data-line-start={184}
                data-line-end={185}
            >
                9. Property
            </h2>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={185}
                data-line-end={186}
            >
                9.1. Property of DigitalMenu.ai
            </h5>
            <p
                className="has-line-data mb-2"
                data-line-start={187}
                data-line-end={188}
            >
                We hold all rights, in particular all Intellectual Property
                Rights and authorizations relating to our website, brands,
                logos, domain names and other distinctive signs, to
                DigitalMenu.ai Services, to the Solution (including the software
                infrastructure implemented or developed by our teams, namely our
                Application) and to its documentation, and more generally to the
                content that we may provide to you in the course of the
                Agreement.
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={189}
                data-line-end={190}
            >
                Any use of the elements comprising the Website or Application
                (symbols, text, graphics, programs, and other objects), except
                as permitted in this Document, without permission is unlawful
                and may result in legal proceedings and civil, administrative,
                and criminal liability in accordance with the law. We grant you
                only a personal, non-assignable and non-transferable right to
                access and use the DigitalMenu.ai Application, for the entire
                duration of the Agreement. This license is reserved solely for
                your use of the DigitalMenu.a Services, to the exclusion of all
                others. You are also authorized to reproduce our trademarks,
                solely in connection with the use of the Solution with the
                Visitors. Your subscription to DigitalMenu.ai Services does not
                imply any transfer or assignment of these rights, of any kind,
                for any reason whatsoever.
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={191}
                data-line-end={192}
            >
                If you are the owner of a trademark and/or other intellectual
                property objects and believe that materials posted on the
                Website or in the Application infringe your rights, please
                contact us at the following email address:{" "}
                <a
                    target="_blank"
                    className="text-indigo-600 underline"
                    rel="noreferrer"
                    href="mailto:support@digital-menu.ai"
                >
                    support@digital-menu.ai
                </a>
                .
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={193}
                data-line-end={194}
            >
                In your communication, please provide the following information:
            </p>
            <ul className="list-disc ml-8 mb-3">
                <li
                    className="has-line-data mb-2"
                    data-line-start={194}
                    data-line-end={195}
                >
                    Identify yourself (name, mailing address, email)
                </li>
                <li
                    className="has-line-data mb-2"
                    data-line-start={195}
                    data-line-end={197}
                >
                    Describe the nature of the intellectual property rights
                    infringement and identify the relevant intellectual property
                    object on the Website or in the Application
                </li>
            </ul>
            <p
                className="has-line-data mb-2"
                data-line-start={197}
                data-line-end={198}
            >
                DigitalMenu.ai hereby guarantees to the Partner that in the
                event of any legal action initiated against the Partner on the
                grounds that our trademarks, DigitalMenu.ai Services, and
                programs provided to the Partner infringe upon the intellectual
                property rights of third parties, DigitalMenu.ai shall indemnify
                the Partner in the event of a final judgment determining such
                infringement. This indemnification shall solely apply to the
                aforementioned circumstances and does not extend to any other
                guarantees or warranties.
            </p>
            <p
                className="has-line-data mb-2"
                data-line-start={199}
                data-line-end={200}
            >
                DigitalMenu.ai does not provide any additional guarantees or
                warranties. In particular, DigitalMenu.ai does not warrant the
                following:
            </p>
            <ul className="list-disc ml-8 mb-3">
                <li
                    className="has-line-data mb-2"
                    data-line-start={200}
                    data-line-end={201}
                >
                    (i) That the DigitalMenu.ai Services will be free from
                    errors or interruptions, or that DigitalMenu.ai will have
                    the capability to rectify all errors present in the
                    DigitalMenu.ai Services.
                </li>
                <li
                    className="has-line-data"
                    data-line-start={201}
                    data-line-end={203}
                >
                    (ii) That the DigitalMenu.ai Services will seamlessly
                    integrate with the Partner’s content, applications, or any
                    other hardware, software, systems, services, or data not
                    provided by DigitalMenu.ai.
                </li>
            </ul>
            <p
                className="has-line-data mb-3"
                data-line-start={203}
                data-line-end={204}
            >
                The Partner acknowledges and agrees that DigitalMenu.ai's
                indemnification obligation is limited solely to intellectual
                property infringement claims as specified herein and does not
                extend to any other liabilities, damages, or losses incurred by
                the Partner.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={205}
                data-line-end={206}
            >
                9.2. Your property rights
            </h5>
            <h6
                className="code-line text-lg font-medium text-indigo-600 mb-1"
                data-line-start={206}
                data-line-end={207}
            >
                9.2.1.
            </h6>
            <p
                className="has-line-data mb-3"
                data-line-start={207}
                data-line-end={208}
            >
                You are the owner of the Data and of the existing rights on the
                information relating to your activity that you communicate as
                well as the materials that you produce within the framework of
                the use of DigitalMenu.ai Service. In particular, you are the
                sole owner of the existing rights on your menu, your logos and
                brands, your graphic design and/or the photographs and content
                that you communicate to us.
            </p>
            <h6
                className="code-line text-lg font-medium text-indigo-600 mb-1"
                data-line-start={209}
                data-line-end={210}
            >
                9.2.2.
            </h6>
            <p
                className="has-line-data mb-3"
                data-line-start={210}
                data-line-end={211}
            >
                You grant us a non-exclusive right to reproduce and represent
                your trademarks, logos, designs, trade names, company names and
                more generally any other rights attached to the content that you
                communicate via the Solution, in order to integrate them into
                the DigitalMenu.ai Service. You authorize us to reproduce and
                represent your trademarks, logos, designs, trade names, company
                names, and more generally any other rights attached as a
                reference, including in the context of marketing operations, on
                any medium and by any process of your choice, such as
                presentations, websites, brochures, etc., to any third party,
                regardless of the method of communication.
            </p>
            <h6
                className="code-line text-lg font-medium text-indigo-600 mb-1"
                data-line-start={212}
                data-line-end={213}
            >
                9.2.3.
            </h6>
            <p
                className="has-line-data"
                data-line-start={213}
                data-line-end={214}
            >
                You warrant that you have all the rights and authorizations
                required to provide your services and/or to subscribe to
                DigitalMenu.ai Service, use our Solution and upload your
                content. You indemnify us, in particular, against any action,
                request or claim that would be made by any third party to whom
                your trademarks, logos, menus, and more generally, all content
                that you can communicate or upload to the Solution, would
                infringe. You also guarantee us against all damages resulting
                from the content that you may upload to the Solution. You agree
                to indemnify DigitalMenu.ai and to bear all defense and
                conviction costs, if any.
            </p>
            <h6
                className="code-line text-lg font-medium text-indigo-600 mb-1"
                data-line-start={215}
                data-line-end={216}
            >
                9.2.4.
            </h6>
            <p
                className="has-line-data mb-5"
                data-line-start={216}
                data-line-end={217}
            >
                In order (i) to allow the functioning of the DigitalMenu.ai
                Service, (ii) to allow you to follow in real time the payment of
                Orders by Visitors in your Establishment(s), (iii) to provide
                you with statistical data in order to improve the management of
                Visitors and (iv) to improve DigitalMenu.ai Service and our
                Solution, you expressly authorize us to access your POS and your
                aggregators/middleware and to collect information relating to
                all the transactions carried out in your Establishment(s).
            </p>
            <h2
                className="code-line text-2xl font-medium text-indigo-600 mb-2"
                data-line-start={218}
                data-line-end={219}
            >
                10. Liability
            </h2>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={220}
                data-line-end={221}
            >
                10.1.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={221}
                data-line-end={222}
            >
                Each of the Parties is liable to the other for the performance
                of its obligations under these Terms and consequently undertakes
                to compensate the other Party for any foreseeable damage
                suffered as a direct result of any non-performance, partial
                performance or improper performance of its obligations.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={223}
                data-line-end={224}
            >
                10.2.
            </h5>
            <p
                className="has-line-data mb-2"
                data-line-start={224}
                data-line-end={225}
            >
                DigitalMenu.ai will make every reasonable effort to ensure the
                proper functioning of the website{" "}
                <a
                    target="_blank"
                    className="text-indigo-600 underline"
                    rel="noreferrer"
                    href="https://digital-menu.ai"
                >
                    https://digital-menu.ai
                </a>{" "}
                and the Application. However, DigitalMenu.ai shall not be held
                liable to Partners or third parties for any direct and/or
                indirect damages, including loss of profit or data, harm to
                reputation, dignity, or business reputation, incurred in
                connection with the use or inability to use the website{" "}
                <a
                    target="_blank"
                    className="text-indigo-600 underline"
                    rel="noreferrer"
                    href="https://digital-menu.ai"
                >
                    https://digital-menu.ai
                </a>{" "}
                or the Application, unauthorized access to Partner’s
                communications by third parties, or the inability to receive
                services, particularly in the following circumstances:
            </p>
            <ul className="list-disc ml-8 mb-3">
                <li
                    className="has-line-data mb-2"
                    data-line-start={226}
                    data-line-end={228}
                >
                    <p
                        className="has-line-data"
                        data-line-start={226}
                        data-line-end={227}
                    >
                        In the event of technological malfunctions of public
                        communication channels or loss of Internet access -
                        until the malfunctions are rectified or access is
                        restored, respectively;
                    </p>
                </li>
                <li
                    className="has-line-data mb-2"
                    data-line-start={228}
                    data-line-end={230}
                >
                    <p
                        className="has-line-data"
                        data-line-start={228}
                        data-line-end={229}
                    >
                        In the event of signs of unauthorized access to
                        Partner’s account management - for the duration of such
                        circumstances;
                    </p>
                </li>
                <li
                    className="has-line-data"
                    data-line-start={230}
                    data-line-end={232}
                >
                    <p
                        className="has-line-data"
                        data-line-start={230}
                        data-line-end={231}
                    >
                        In the event of equipment security breaches or equipment
                        malfunctions (personal computer, smartphone) used by the
                        Partner to access the services on the website{" "}
                        <a
                            target="_blank"
                            className="text-indigo-600 underline"
                            rel="noreferrer"
                            href="https://digital-menu.ai"
                        >
                            https://digital-menu.ai
                        </a>{" "}
                        (access to the Account).
                    </p>
                </li>
            </ul>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={232}
                data-line-end={233}
            >
                10.3. You are solely responsible:
            </h5>
            <ul className="list-disc ml-8 mb-3">
                <li
                    className="has-line-data mb-2"
                    data-line-start={234}
                    data-line-end={236}
                >
                    for complying with these Terms, as well as with any special
                    conditions, additional terms and the Documentation,
                    <br />
                    for updating the Data that you communicate to us, whether
                    they relate to your activity, to that of your
                    Establishment(s) or to the Beneficiary Companies,
                </li>
                <li
                    className="has-line-data mb-2"
                    data-line-start={236}
                    data-line-end={237}
                >
                    for obtaining all the appropriate authorizations to carry
                    out your activity and allow the Beneficiary Companies or
                    your Establishment(s) to benefit from the DigitalMenu.ai
                    Service,
                </li>
                <li
                    className="has-line-data mb-2"
                    data-line-start={237}
                    data-line-end={238}
                >
                    in the event of identity theft, fraud, unauthorized use of
                    your Merchant Account, or in the event of misappropriation
                    by a third party of the data in said account to use the
                    Solution, as a consequence of any breach by you of the
                    Terms,
                </li>
                <li
                    className="has-line-data mb-2"
                    data-line-start={238}
                    data-line-end={239}
                >
                    for fulfilling Orders placed by the Visitors via the
                    Application. Once you accept an Order from of a Visitor, you
                    are solely responsible for the conditions under which you
                    provide your services to Visitors (including, in particular,
                    updating of your menus, the availability of products, the
                    quality of the products and services that you offer, the
                    progress of the Order placement, the display of the
                    allergens for your products),
                </li>
                <li
                    className="has-line-data"
                    data-line-start={239}
                    data-line-end={241}
                >
                    for all tax and social security consequences resulting from
                    the use of the Solution and DigitalMenu.ai Services, and the
                    provision of your services with respect to any third party
                    and any administrative or public authority.
                </li>
            </ul>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={241}
                data-line-end={242}
            >
                10.4.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={242}
                data-line-end={243}
            >
                Except as expressly stated in this Agreement, all terms,
                conditions, representations, and warranties (whether implied by
                statute, course of conduct, or otherwise) are hereby excluded to
                the fullest extent permitted by law with respect to
                DigitalMenu.ai Service.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={244}
                data-line-end={245}
            >
                10.5.
            </h5>
            <p
                className="has-line-data mb-2"
                data-line-start={245}
                data-line-end={246}
            >
                Finally, the Solution implies an Internet connection within your
                Establishment(s). You are informed and aware of the hazards of
                the Internet. We shall not be held responsible for the
                consequences of any Internet connection problem.
            </p>
            <p
                className="has-line-data mb-5"
                data-line-start={247}
                data-line-end={248}
            >
                As such, you agree to hold DigitalMenu.ai harmless from any
                dispute, challenge, damage or injury that may arise from your
                failure to comply with this section.
            </p>
            <h2
                className="code-line text-2xl font-medium text-indigo-600 mb-2"
                data-line-start={249}
                data-line-end={250}
            >
                11. Personal data
            </h2>
            <p
                className="has-line-data mb-5"
                data-line-start={250}
                data-line-end={251}
            >
                You and we undertake that the processing of Personal Data
                carried out in the context of our relationship shall comply with
                the applicable Data Protection Regulations. The Parties
                undertake to implement all appropriate technical and
                organizational measures so that the processing of Personal Data
                meets the requirements of the Data Protection Regulations and
                guarantees the protection of the data subject’s rights. In this
                respect, each Party will communicate to the other all documents
                and personal data policies governing its activity.
            </p>
            <h2
                className="code-line text-2xl font-medium text-indigo-600 mb-2"
                data-line-start={253}
                data-line-end={254}
            >
                12. Other rights and obligations
            </h2>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={254}
                data-line-end={255}
            >
                12.1.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={255}
                data-line-end={256}
            >
                The Operator is entitled to restrict or interrupt the
                functioning of the DigitalMenu.ai Application, or access to
                same, for the period absolutely necessary for the maintenance or
                repair of the DigitalMenu.ai Application, or for another reason
                on the part of the Operator or a third party. The Operator is
                also entitled to modify the technical solution of the
                DigitalMenu.ai Application for operational or other reasons.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={257}
                data-line-end={258}
            >
                12.2.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={258}
                data-line-end={259}
            >
                The Operator is entitled to unilaterally offset, at any time,
                their payable and non-payable receivables due from the Partner
                (including receivables due to the price of services arising on a
                basis other than the Contract) against the Partner’s payable and
                non-payable receivables due from the Operator.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={260}
                data-line-end={261}
            >
                12.3.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={261}
                data-line-end={262}
            >
                The Partner is not entitled to offset any of their receivables
                due from the Operator against the Operator’s receivables due
                from the Partner without their written consent. The Partner is
                also not entitled to assign their receivables due from the
                Operator to a third party without the Operator’s written
                consent.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={263}
                data-line-end={264}
            >
                12.4.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={264}
                data-line-end={265}
            >
                The Contracting Parties undertake to maintain confidentiality in
                relation to classified information and all facts that constitute
                the Contracting Parties‘ trade secrets. Confidential information
                is considered, in particular, all information relating to the
                Contracting Parties that is subject to protection as per the
                regulations governing the area of intellectual property (e.g.
                information regarding information systems, know-how and
                individual components thereof).
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={266}
                data-line-end={267}
            >
                12.5.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={267}
                data-line-end={268}
            >
                The Partner explicitly consents to the processing, storage, use
                and downloading of data that is processed within the scope of
                the DigitalMenu.ai Application by the Operator for the purpose
                of (i) internal use by the Operator and (ii) processing and
                publication in an aggregated or more precisely anonymised form
                (e.g. for the purposes of various studies including case
                studies, statistical reports, infographics etc.). The Partner
                also explicitly consents to this data being downloaded,
                processed and stored in the Operator’s database even after the
                end of the provision of the Services, including data that was
                obtained from appropriate sources after the termination of the
                Contract and before the disconnection of the sources that this
                data comes from. The Partner also acknowledges, and explicitly
                agrees with, the fact the personal data of the Partner or their
                representatives is processed by the Operator, as the Controller,
                in connection with the Provision of the Services.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={269}
                data-line-end={270}
            >
                12.6.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={270}
                data-line-end={271}
            >
                The Partner hereby grants their consent to the Operator to use
                the Partner’s name, logo and trade name (hereinafter “Name“) in
                all communication media for advertising purposes, primarily in
                the form of the promotion of the DigitalMenu.ai Application and
                the Services. The Partner explicitly declares that they have the
                right to grant such consent. In cases where the Partner requires
                the Operator to abide by certain rules when using the Name (e.g.
                those contained in the graphic manual for the use of the given
                Name), the Partner must inform the Operator of these rules in
                writing, otherwise these rules will not be taken into
                consideration when using the Name. The Partner has the right to
                revoke their consent to the use of the Name, or restrict it via
                a written declaration delivered to the Operator.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={272}
                data-line-end={273}
            >
                12.7.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={273}
                data-line-end={274}
            >
                While using the Operator’s Services and the DigitalMenu.ai
                Application, the Partner undertakes to comply with the
                applicable and effective legal regulations of Hungary and the
                European Union, in particular the Copyright Act. The Partner is
                obliged to compensate the Operator or third parties in full for
                all damage caused to them due to a breach of this obligation,
                that the Partner is responsible for.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={275}
                data-line-end={276}
            >
                12.8.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={276}
                data-line-end={277}
            >
                The Operator is entitled to deny the Partner access to the
                DigitalMenu.ai Application services if the Partner breaches
                their obligations arising from this Contract and/or legal
                regulations.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={278}
                data-line-end={279}
            >
                12.9.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={279}
                data-line-end={280}
            >
                The Operator also reserves the right to restrict the provision
                of the Services, or terminate it without compensation, at any
                time and even without prior notification.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={281}
                data-line-end={282}
            >
                12.10.
            </h5>
            <p
                className="has-line-data mb-5"
                data-line-start={282}
                data-line-end={283}
            >
                The Partner acknowledges that the Operator provides them with
                the personal data of Users who use the DigitalMenu.ai
                Application services, and conclude Brokered Agreements with the
                Partner, via the DigitalMenu.ai Application. The Partner also
                acknowledges that they are in the position of Personal Data
                Controller in relation to Users‘ personal data, and undertakes
                to process this personal data in accordance with legal
                regulations relating to personal data processing and protection,
                in particular but not exclusively Regulation (EU) 2016/679 of
                the European Parliament and of the Council, of the 27th of April
                2016, on the protection of natural persons with regard to the
                processing of personal data and on the free movement of such
                data, and repealing Directive 95/46/EC (General Data Protection
                Regulation – GDPR) and Act no. 110/2019 Coll., on Personal Data
                Processing, as amended.
            </p>
            <h2
                className="code-line text-2xl font-medium text-indigo-600 mb-2"
                data-line-start={284}
                data-line-end={285}
            >
                13. Final provisions
            </h2>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={286}
                data-line-end={287}
            >
                13.1.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={287}
                data-line-end={288}
            >
                All relationships arising from the Terms and the Contracts are
                governed by the Budapest-Capital Regional Court. All disputes
                between the Contracting Parties, arising from the Contract or in
                connection with it, will be resolved by the general courts of
                Hungary.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={289}
                data-line-end={290}
            >
                13.2.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={290}
                data-line-end={291}
            >
                If any provision of these Terms is or becomes invalid or
                ineffective, then the other provisions of these Terms remain
                valid and effective. The invalid or ineffective provision will
                be replaced by another, valid and effective provision, whose
                content and meaning best corresponds to the content and meaning
                of the original provision.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={292}
                data-line-end={293}
            >
                13.3.
            </h5>
            <p
                className="has-line-data mb-3"
                data-line-start={293}
                data-line-end={294}
            >
                The Operator is entitled to unilaterally amend and supplement
                the wording of these Terms. The Operator will notify the Partner
                of the amendment to the Terms at least 14 (fourteen) days before
                it comes into effect, including the date of effectiveness of the
                amendment, by displaying a notification in the DigitalMenu.ai
                Application or using the Partner’s e-mail address set forth in
                the Contract. If the Partner does not agree with the amendment
                to the Terms, then they have the right to notify the Operator in
                writing that they do not consent to the new version of the
                Terms, and withdraw from the Contract in writing for this
                reason, no later than the time the new Terms come into effect.
                In that case, the withdrawal from the Contract is effective the
                day that the new Terms come into effect. If the Partner does not
                withdraw from the Contract as per the previous provisions, then
                they are bound by the new Terms from the first day of their
                effectiveness.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={295}
                data-line-end={296}
            >
                13.4.
            </h5>
            <p
                className="has-line-data mb-10"
                data-line-start={296}
                data-line-end={297}
            >
                The Contracting Parties are entitled to withdraw from the
                Contract in the event of a significant breach of same by the
                other Contracting Party.
            </p>
        </div>
    );
};
