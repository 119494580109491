/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { RoughNotation } from "react-rough-notation";
import phone1 from "../assets/block_1.png";
import phone2 from "../assets/block_2.png";
import phone3 from "../assets/block_3.png";
import phone4 from "../assets/block_4.png";
import phone5 from "../assets/block_5.png";
import AdvantagesBlock from "../components/AdvantagesBlock";
import { FormattedMessage } from "react-intl";

const data = [
  {
    title: <FormattedMessage id="visualBeautiful" />,
    decr: <FormattedMessage id="modernDesign" />,
    img: phone1,
    id: 1,
  },
  {
    title: <FormattedMessage id="menuBuilder" />,
    decr: <FormattedMessage id="createCategoriesForMenu" />,
    img: phone2,
    id: 2,
  },
  {
    title: <FormattedMessage id="allergens" />,
    decr: <FormattedMessage id="allergiesAndDietary" />,
    img: phone3,
    id: 3,
  },
  {
    title: <FormattedMessage id="extrasOptions" />,
    decr: <FormattedMessage id="optionOfChoosing" />,
    img: phone4,
    id: 4,
  },
  {
    title: <FormattedMessage id="soldOut" />,
    decr: <FormattedMessage id="switchOffMenu" />,
    img: phone5,
    id: 5,
  },
];

export default function Advantages() {
  const [active, setActive] = useState(0);
  const [direction, setDirection] = useState("up");
  let oldScrollY = 0;

  const handleActiveClick = (index) => {
    setActive(index);
  };

  const controlDirection = () => {
    if (window.scrollY > oldScrollY) {
      const phantomSteps = document.querySelector(".phantom-steps");
      if (phantomSteps.getBoundingClientRect().top <= 65) {
        phantomSteps.querySelectorAll(".step").forEach((step, i) => {
          if (
            step.getBoundingClientRect().top <= 65 &&
            step.getBoundingClientRect().top > 0
          ) {
            if (i > data.length - 1) {
              setActive(data.length - 1);
            } else {
              setActive(i);
            }
          }
        });
      }
      setDirection("down");
    } else {
      const phantomSteps = document.querySelector(".phantom-steps");
      if (phantomSteps.getBoundingClientRect().top <= 65) {
        phantomSteps.querySelectorAll(".step").forEach((step, i) => {
          if (
            step.getBoundingClientRect().top >= 65 &&
            step.getBoundingClientRect().top < 130 &&
            i !== 0
          ) {
            if (i > data.length - 1) {
              setActive(data.length - 1);
            } else {
              setActive(i);
            }
          } else if (i === 0 && step.getBoundingClientRect().top > -30) {
            setActive(0);
          }
        });
      }
      setDirection("up");
    }
    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    if (window.innerWidth >= 912) {
      window.addEventListener("scroll", controlDirection);
      return () => {
        window.removeEventListener("scroll", controlDirection);
      };
    }
  }, []);

  return (
    <div
      className="min-h-screen relative -mt-5 bg-gray-100"
      id="advantages-components-wrap"
    >
      <div className="w-full h-full md:absolute md:top-0 bg-gray-100">
        <div className="md:sticky md:top-[55px] lg:top-[105px] w-full z-100 bg-gray-100">
          <div
            id="advantages-components"
            className="flex flex-col md:flex-row bg-gray-100 py-6 px-8 md:py-24 md:px-24 md:pb-40 lg:py-24 lg:px-44 lg:pb-40 sm:pb-32 md:h-screen"
            //style={{ top: "-20px" }}
          >
            <div className="w-[100%] md:w-[55%] lg:w-[60%] md:mr-10 lg:mr-5 hidden md:block">
              <img
                style={{
                  transition: "ease-in 0.3s",
                }}
                className="wow slideInLeft relative top-[-100px] h-full max-h-[700px] w-full object-contain"
                src={data[active].img}
                alt=""
              />
            </div>
            <div className="w-[100%] md:w-[40%]">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl relative top-[-50px] leading-relaxed">
                <RoughNotation type="underline" color="#4338ca" show={true}>
                  <FormattedMessage id="functionality" />{" "}
                </RoughNotation>
                <FormattedMessage id="ofDigitalMenu" />
              </p>
              <ul className="space-y-3 w-full text-2xl">
                {data.map((elem, index) => {
                  return (
                    <AdvantagesBlock
                      key={'advantages-' + elem.id}
                      elem={elem}
                      index={index}
                      handleActiveClick={handleActiveClick}
                      isActive={index === active}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="phantom-steps hidden md:block">
        <div className="w-[50vw] h-[50vh] step"></div>
        <div className="w-[50vw] h-[50vh] step"></div>
        <div className="w-[50vw] h-[50vh] step"></div>
        <div className="w-[50vw] h-[50vh] step"></div>
        <div className="w-[50vw] h-[50vh] step"></div>

        <div className="w-[50vw] h-[50vh] step"></div>
      </div>
    </div>
  );
}
