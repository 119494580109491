import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import React, { Fragment } from "react";
import { phoneCodes } from "../constants";
import { classNames } from "../helpers";

export const PhoneCodeSelect = ({ phoneCode, setPhoneCode }) => {
    return (
        <Listbox value={phoneCode} onChange={setPhoneCode}>
            {({ open }) => (
                <>
                    <div className="relative">
                        <Listbox.Button
                            as="div"
                            className="relative w-[105px] cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-8 text-left shadow-sm md:focus:border-indigo-500 md:focus:outline-none md:focus:ring-1 md:focus:ring-indigo-500 sm:text-sm"
                        >
                            <span className="block truncate text-gray-500">
                                {phoneCode.name}
                                {"  "}
                                {phoneCode.code}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="scrollbar absolute z-10 mt-1 max-h-60 w-auto overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none sm:text-sm">
                                {phoneCodes.map((person) => (
                                    <Listbox.Option
                                        key={person.code}
                                        className={({ active }) =>
                                            classNames(
                                                active
                                                    ? "text-white bg-indigo-600"
                                                    : "text-gray-900",
                                                "relative cursor-default select-none py-2 pl-8 pr-4"
                                            )
                                        }
                                        value={person}
                                    >
                                        {({ selected, active }) => {
                                            return (
                                                <>
                                                    <span
                                                        className={classNames(
                                                            selected
                                                                ? "font-semibold"
                                                                : "font-normal",
                                                            "block truncate"
                                                        )}
                                                    >
                                                        {person.name}
                                                        {"  "}
                                                        {person.code}
                                                    </span>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active
                                                                    ? "text-white"
                                                                    : "text-indigo-600",
                                                                "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                                            )}
                                                        >
                                                            <CheckIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    ) : null}
                                                </>
                                            );
                                        }}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
};
