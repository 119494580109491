import React from "react";

export const PrivacyPolicy = () => {
    return (
        <div className="text-indigo-500">
            <h1
                className="code-line text-3xl font-medium text-indigo-600 mb-1"
                data-line-start={0}
                data-line-end={1}
            >
                Cookies Policy
            </h1>
            <p
                className="has-line-data text-indigo-500"
                data-line-start={1}
                data-line-end={3}
            >
                May 2023
                <br />
                This information applies for the use of the websites{" "}
                <a
                    href="http://www.digital-menu.ai"
                    target="_blank"
                    rel="noreferrer"
                    className="text-indigo-600 underline"
                >
                    www.digital-menu.ai
                </a>{" "}
                operated by DigitalMenu.ai KFT, Identification Number:
                18-09-115586, registered office 9752 Kenéz, Kossuth Lajos utca
                6. This Cookie Policy describes the practices that
                “DigitalMenu.ai” (or “us” or “we”) follows when collecting
                information through use of cookies and similar technologies when
                you access our services. You can contact us at the registered
                office, or by e-mail at support@digital-menu.ai
            </p>
            <p
                className="has-line-data mt-4 text-indigo-500"
                data-line-start={4}
                data-line-end={5}
            >
                This Cookie Policy explains how our SaaS product uses cookies
                and similar technologies to enhance the user experience, analyze
                usage, and personalize content and ads. By using our product,
                you consent to our use of cookies in accordance with this
                policy.
            </p>
            <h3
                className="code-line mt-4 text-2xl font-medium text-indigo-600 mb-1"
                data-line-start={6}
                data-line-end={7}
            >
                What are cookies?
            </h3>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={8}
                data-line-end={9}
            >
                Cookies are small text files that are stored on your device by
                websites and applications that you use. They are widely used to
                make websites and applications work more efficiently and to
                provide information to the owners of the site or application.
                Cookies can be either “session cookies,” which expire when you
                close your browser, or “persistent cookies,” which remain on
                your device until they expire or are deleted.
            </p>
            <h3
                className="code-line text-2xl font-medium text-indigo-600"
                data-line-start={10}
                data-line-end={11}
            >
                1. How we use cookies?
            </h3>
            <p
                className="has-line-data mt-2 mb-2 text-indigo-500"
                data-line-start={12}
                data-line-end={13}
            >
                We use cookies and similar technologies to:
            </p>
            <ul className="list-disc ml-8">
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={13}
                    data-line-end={14}
                >
                    <strong>Personalize your experience</strong>: We use cookies
                    to remember your preferences and settings, such as language
                    and location, to enhance your experience on our product.
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={14}
                    data-line-end={15}
                >
                    <strong>Analyze usage</strong>: We use cookies to understand
                    how users interact with our product, including which pages
                    or features are most popular. This helps us improve our
                    product and develop new features.
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={15}
                    data-line-end={17}
                >
                    <strong>Advertisements</strong>: We use cookies to deliver
                    personalized advertisements to our users based on their
                    interests and browsing behavior. This helps us provide more
                    relevant and useful ads to our users.
                </li>
            </ul>
            <h3
                className="code-line text-2xl font-medium text-indigo-600 mt-4 mb-2"
                data-line-start={17}
                data-line-end={18}
            >
                2. Types of cookies we use
            </h3>
            <ul className="list-disc ml-8">
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={19}
                    data-line-end={20}
                >
                    <strong>Strictly necessary cookies</strong>: These cookies
                    are essential for our product to function properly and
                    cannot be disabled. They are usually set in response to
                    actions made by you, such as logging in, filling out forms,
                    or selecting preferences.
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={20}
                    data-line-end={21}
                >
                    <strong>Performance cookies</strong>: These cookies help us
                    analyze how our product is being used, such as which pages
                    are visited most often or which features are used the most.
                    They also help us improve the performance and speed of our
                    product.
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={21}
                    data-line-end={22}
                >
                    <strong>Functionality cookies</strong>: These cookies
                    remember your preferences and settings, such as language and
                    location, and personalize your experience on our product.
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={22}
                    data-line-end={23}
                >
                    <strong>Targeting cookies</strong>: These cookies are used
                    to deliver personalized advertisements to you based on your
                    interests and browsing behavior. They also help us measure
                    the effectiveness of our ad campaigns.
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={23}
                    data-line-end={24}
                >
                    <strong>Third-party cookies</strong>: We also use
                    third-party cookies from trusted partners to help us analyze
                    usage and deliver personalized advertisements. These cookies
                    may track your browsing behavior across multiple websites
                    and applications and are subject to the privacy policies of
                    those websites and applications.
                </li>
            </ul>
            <h3
                className="code-line text-2xl font-medium text-indigo-600 mt-4 mb-2"
                data-line-start={26}
                data-line-end={27}
            >
                2.1 Analytics Cookies
            </h3>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={27}
                data-line-end={28}
            >
                Analysis cookies allow us to understand how you interact with
                our services in order to analyze, research and improve them
                (also with the help of third-party data analysis tools).
            </p>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={29}
                data-line-end={30}
            >
                The analysis cookies which we use are as follows:
            </p>
            <div className="flow-root mb-5">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="table table-striped table-bordered table-auto min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-indigo-900 sm:pl-6"
                                        >
                                            Name of Cookie
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-indigo-900 sm:pl-6"
                                        >
                                            Purpose of the cookie
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-indigo-900 sm:pl-6"
                                        >
                                            Expiration
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    <tr>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-indigo-900 sm:pl-6">
                                            Google Analytics (Analytics
                                            Providers)
                                        </td>
                                        <td className=" px-3 py-4 text-sm text-indigo-500">
                                            To collect demographic and
                                            interest-level information and usage
                                            information from you when you visit
                                            our Site (including information
                                            about the pages you entered and exit
                                            the Site and what pages you viewed,
                                            time spent, browser, operating
                                            system, and IP address etc.). To
                                            recognize you when you visit our
                                            Site and when you visit other sites.
                                            For more information regarding
                                            Google’s use of cookies, and
                                            collection and use of information,
                                            see the{" "}
                                            <a
                                                href="https://policies.google.com/privacy?hl=en"
                                                target="_blank"
                                                className="text-indigo-600 underline"
                                                rel="noreferrer"
                                            >
                                                Google Privacy Policy
                                            </a>
                                            . If you would like to opt out of
                                            Google Analytics tracking, please
                                            visit the{" "}
                                            <a
                                                href="https://tools.google.com/dlpage/gaoptout"
                                                target="_blank"
                                                className="text-indigo-600 underline"
                                                rel="noreferrer"
                                            >
                                                Google Analytics Opt-out Browser
                                                Add-on
                                            </a>
                                            .
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-indigo-500">
                                            11 months
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <h3
                className="code-line text-2xl font-medium text-indigo-600 mb-2"
                data-line-start={35}
                data-line-end={36}
            >
                3. How can you change your preferences or block cookies?
            </h3>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={37}
                data-line-end={38}
            >
                You can change your consent preferences regarding the use of
                cookies by accessing your preference center.
            </p>
            <h3
                className="code-line text-2xl font-medium text-indigo-600 mb-2"
                data-line-start={39}
                data-line-end={40}
            >
                4. Is your consent required for cookies?
            </h3>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={41}
                data-line-end={42}
            >
                When you use our services, you are notified of the use of
                cookies and asked to provide your consent for cookies which are
                not strictly necessary for the services proper operation (for
                example, Targeting / Advertising cookies).
            </p>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={43}
                data-line-end={44}
            >
                In addition, you can prevent or restrict the storage of cookies
                on your hard disk by setting your browser not to accept cookies
                or to request your permission before setting cookies. Once
                cookies have been set, you can delete them at any time.
            </p>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={45}
                data-line-end={46}
            >
                Please refer to your browser’s operating instructions to find
                out how this works. If you choose to disable cookies, some
                features of our website or Services may not operate as intended.
            </p>
            <h3
                className="code-line text-2xl font-medium text-indigo-600 mb-2 mt-2"
                data-line-start={47}
                data-line-end={48}
            >
                5. Updates to this policy
            </h3>
            <p
                className="has-line-data text-indigo-500 mb-5"
                data-line-start={49}
                data-line-end={50}
            >
                We may update this Cookie Policy from time to time to reflect
                changes in our use of cookies or for other operational, legal,
                or regulatory reasons. We encourage you to periodically review
                this policy to stay informed about our use of cookies.
            </p>
            <h1
                className="code-line text-3xl font-medium text-indigo-600 mb-1"
                data-line-start={51}
                data-line-end={52}
            >
                Privacy Policy
            </h1>
            <p
                className="has-line-data text-indigo-500"
                data-line-start={53}
                data-line-end={54}
            >
                May 2023
            </p>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={55}
                data-line-end={56}
            >
                We (“we” or “DigitalMenu” or “us”) prioritize compliance with
                the regulations concerning the protection of your Personal Data
                (the “Regulations”). This applies to all individuals using our
                Services, whether you are a subscribed Client, an Establishment
                using our Solution, an end customer utilizing our Application
                for payments, a job candidate seeking employment, or a visitor
                on our website{" "}
                <a
                    href="https://digital-menu.ai/"
                    target="_blank"
                    className="text-indigo-600 underline"
                    rel="noreferrer"
                >
                    Site
                </a>
                .
            </p>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={57}
                data-line-end={58}
            >
                This Privacy Policy pertains to all Personal Data (defined as
                any data related directly or indirectly to a natural person,
                such as an identifier, name email, phone, identification number,
                location data, online identifier that we gather, handle,
                transfer, store, keep, or utilize while using our Services,
                irrespective of the media on which it is stored. The handling of
                Personal Data is subject to specific legal protections,
                depending on the data subject’s location, as outlined in the
                Regulations. Our Privacy Policy aims to explain the terms under
                which we, our agents, employees, representatives, third-party
                service providers, or anyone else who has access to the Personal
                Data we collect, will manage the Personal Data.
            </p>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={59}
                data-line-end={60}
            >
                As a result, whether you utilize our website, Application,
                Services, or Solution, or if you send us inquiries for
                information or apply for a job with us, you are required to
                review and agree to our Privacy Policy.
            </p>
            <h1
                className="code-line text-2xl font-medium text-indigo-600 mb-1"
                data-line-start={61}
                data-line-end={62}
            >
                1. Personal Data Protection Principles
            </h1>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={63}
                data-line-end={64}
            >
                These personal data processing principles (hereinafter
                “Principles“) govern personal data processing during the use of
                the DigitalMenu.ai Application services (hereinafter
                “DigitalMenu Service“) by its users (hereinafter “User“ or
                “you“).
            </p>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={65}
                data-line-end={66}
            >
                We adhere to general data privacy principles when collecting and
                processing Personal Data that require us to:
            </p>
            <ul className="list-disc ml-8 mb-4">
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={66}
                    data-line-end={67}
                >
                    Collect and use Personal Data fairly and only for lawful and
                    specified purposes related to our legitimate business
                    objectives.
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={67}
                    data-line-end={68}
                >
                    Limit our Personal Data collection to what is adequate,
                    relevant, and not excessive for the intended purpose.
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={68}
                    data-line-end={69}
                >
                    Notify individuals about our Personal Data processing
                    practices in a clear and transparent manner.
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={69}
                    data-line-end={70}
                >
                    Ensure the accuracy of the Personal Data we collect, hold,
                    and use.
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={70}
                    data-line-end={71}
                >
                    Retain Personal Data only for the time needed to fulfill the
                    established purpose.
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={71}
                    data-line-end={72}
                >
                    Respect data subjects’ rights.
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={72}
                    data-line-end={74}
                >
                    Secure the Personal Data we hold.
                </li>
            </ul>
            <h1
                className="code-line text-2xl font-medium text-indigo-600 mb-3"
                data-line-start={74}
                data-line-end={75}
            >
                2. What Personal Data do we collect?
            </h1>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={76}
                data-line-end={77}
            >
                2.1.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={77}
                data-line-end={78}
            >
                The Controller processes only personal data that the User
                themselves provided to them. The User declares that all personal
                data provided by the User to the Controller is truthful,
                accurate, up-to-date, correct and complete. The Controller does
                not assume any obligation to verify this information. If you
                provide any information that is false, inaccurate, out of date
                or incomplete, or if we have reasonable suspicion that the
                information is false, inaccurate, out of date or incomplete,
                then we are entitled to suspend or terminate our collaboration,
                and deny all current or future use of the DigitalMenu
                Application services (or any part thereof).
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={78}
                data-line-end={79}
            >
                2.2.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-1"
                data-line-start={79}
                data-line-end={80}
            >
                In connection with your use of the DigitalMenu Application
                services, the Controller processes the following personal data:
            </p>
            <ul className="list-disc ml-8 mb-3">
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={80}
                    data-line-end={81}
                >
                    identification details (in particular name, surname,
                    username and password);
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={81}
                    data-line-end={82}
                >
                    contact details (in particular e-mail address and telephone
                    number);
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={82}
                    data-line-end={83}
                >
                    payment details (in particular order payment information);
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={83}
                    data-line-end={85}
                >
                    other information that you provide to us in connection with
                    the use of the DigitalMenu Application services, or that we
                    obtain in this context (information about your consumer
                    behaviour etc.).
                </li>
            </ul>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={85}
                data-line-end={86}
            >
                2.3.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={86}
                data-line-end={87}
            >
                When you browse our Site or use our Application, we may also
                collect your IP address via our cookies. For more information,
                see our Cookies Policy.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={88}
                data-line-end={89}
            >
                2.4.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-2"
                data-line-start={89}
                data-line-end={90}
            >
                When you are a Client operating one or more Establishment(s) or
                having registered Beneficiary Companies, you have ensured that
                you have obtained all the appropriate authorizations to
                communicate the Personal Data of the persons concerned to us.
            </p>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={91}
                data-line-end={92}
            >
                Moreover, on your side, as a data processor, you are required to
                ensure that the processing of Personal Data of our teams and/or
                service providers as well as of the teams of your Establishments
                and Beneficiary Companies, if any, and those concerning your end
                customers, are in full compliance with the law and the
                Regulations. These processing operations are carried out under
                your sole responsibility. It is also your responsibility to
                communicate our Privacy Policy to each member of your staff in
                order to ensure their prior consent.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={93}
                data-line-end={94}
            >
                2.5.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={94}
                data-line-end={95}
            >
                All of the obligations incumbent upon you under this section are
                essential. You will indemnify us for any and all claims, actions
                or damages and costs that may arise from any breach by you of
                this Privacy Policy or any applicable Regulations.
            </p>
            <h1
                className="code-line text-2xl font-medium text-indigo-600 mb-3"
                data-line-start={97}
                data-line-end={98}
            >
                3. Why do we collect Personal Data?
            </h1>
            <p
                className="has-line-data text-indigo-500 mb-2"
                data-line-start={99}
                data-line-end={100}
            >
                The processing of Personal Data is strictly necessary for the
                business purpose you have entrusted to us, for the use of our
                Services, the Solution, the Application, the use of our Site or
                the processing of your requests. We will notify you of the
                specific business purpose when we first collect the data. Any
                Personal Data collection and processing we conduct must also
                comply with that notice and any other related privacy
                disclosures. We cannot use Personal Data for new, different, or
                incompatible purposes unless the data subject consents to the
                new use after receiving a revised privacy notice.
            </p>
            <p
                className="has-line-data text-indigo-500 mb-2"
                data-line-start={101}
                data-line-end={102}
            >
                Therefore, they are only used for:
            </p>
            <ul className="list-disc ml-8 mb-4">
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={102}
                    data-line-end={103}
                >
                    The verification of your identity and status,
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={103}
                    data-line-end={104}
                >
                    The execution and the follow-up of the contract which binds
                    us to you,
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={104}
                    data-line-end={105}
                >
                    The creation of your account and your accesses,
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={105}
                    data-line-end={106}
                >
                    The use of the Solution, the Services, the Site or the
                    Application,
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={106}
                    data-line-end={107}
                >
                    Our marketing and sales prospecting operations, subject to
                    your prior consent,
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={107}
                    data-line-end={108}
                >
                    The processing of your requests for information and quotes
                    and your job applications,
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={108}
                    data-line-end={109}
                >
                    The management of unpaid bills, claims and possible
                    litigation,
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={109}
                    data-line-end={110}
                >
                    The processing of requests to exercise your rights in
                    accordance with section 5 below,
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={110}
                    data-line-end={112}
                >
                    More generally, the respect of legal and regulatory
                    obligations incumbent upon us.
                </li>
            </ul>
            <p
                className="has-line-data text-indigo-500 mb-2"
                data-line-start={112}
                data-line-end={113}
            >
                <strong>Aggregate Information</strong>: We may share aggregate
                and anonymized/pseudonymized Personal Data to third parties in
                order to promote or describe use of our Services, the Solution
                or the Application, for research, marketing, advertising, or
                other commercial purposes.
            </p>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={114}
                data-line-end={115}
            >
                In order to allow us to constantly improve our Services and our
                Solution, we may also anonymize Personal Data.
            </p>
            <h1
                className="code-line text-2xl font-medium text-indigo-600 mb-3"
                data-line-start={116}
                data-line-end={117}
            >
                4. Conditions and purpose of the personal data processing
            </h1>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={118}
                data-line-end={119}
            >
                4.1.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-2"
                data-line-start={119}
                data-line-end={120}
            >
                The processing of personal data by the Controller is lawful, as
                at least one of the following conditions is always fulfilled
                during the processing:
            </p>
            <ul className="list-disc ml-8 mb-4">
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={120}
                    data-line-end={121}
                >
                    the User granted their consent to the processing of their
                    personal data as per Article 6 paragraph 1 section a) of the
                    GDPR for one or more specific purposes
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={121}
                    data-line-end={122}
                >
                    the processing of the User’s personal data is necessary for
                    the fulfilment arising from the Contract to which the User
                    is a party, or the implementation of measures taken before
                    the conclusion of the Contract at this User’s request as per
                    Article 6 paragraph 1 section b) of the GDPR
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={122}
                    data-line-end={123}
                >
                    the processing of the User’s personal data is necessary for
                    the fulfilment of the legal obligations that applies to the
                    Controller
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={123}
                    data-line-end={124}
                >
                    the processing of the User’s personal data is necessary for
                    the purposes of the Controller’s legitimate interests as per
                    Article 6 paragraph 1 section f) of the GDPR
                </li>
            </ul>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={126}
                data-line-end={127}
            >
                4.2.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-2"
                data-line-start={127}
                data-line-end={128}
            >
                In connection with the use of the DigitalMenu Application
                services, the Controller processes Users personal data for the
                following purposes:
            </p>
            <ul className="list-disc ml-8 mb-4">
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={128}
                    data-line-end={129}
                >
                    processing based on the necessity of such processing for the
                    separate fulfilment of the Contract concluded in connection
                    with the provision of services via the DigitalMenu
                    Application;
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={129}
                    data-line-end={130}
                >
                    processing based on the fulfilment of our legal obligations
                    for the purposes of fulfilling these legal obligations;
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={130}
                    data-line-end={131}
                >
                    processing due to our legitimate interest, where this reason
                    leads, in particular, to personal data processing for the
                    purpose of direct marketing or for statistical and
                    analytical purposes, for the storage of certain information
                    for the purposes of the possible enforcement of ownership
                    claims;
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={131}
                    data-line-end={133}
                >
                    processing based on your consent, if it was provided to the
                    Controller, where personal data is processed for marketing
                    and advertising purposes on the basis of this consent.
                </li>
            </ul>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={133}
                data-line-end={134}
            >
                4.3.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={134}
                data-line-end={135}
            >
                The personal data is processed for the period absolutely
                necessary with regard to the purpose of its processing, or for
                the period specified in the consent to the personal data
                processing.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={135}
                data-line-end={136}
            >
                4.4.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={136}
                data-line-end={137}
            >
                With regard to the actual provision of services via the
                DigitalMenu Application, personal data is provided to the
                individual business partners whose services are mediated by the
                DigitalMenu Application.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={138}
                data-line-end={139}
            >
                4.5.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={139}
                data-line-end={140}
            >
                With regard to the legal obligation to perform mandatory
                sanction and AML monitoring (anti-money laundering measures) of
                transactions executed via the DigitalMenu Application, personal
                data may be provided to Stripe{" "}
                <a
                    href="http://stripe.com"
                    target="_blank"
                    className=" text-indigo-600 underline"
                    rel="noreferrer"
                >
                    (stripe.com)
                </a>
                , as the operator of the payment gateway in the DigitalMenu
                Application.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={141}
                data-line-end={142}
            >
                4.6.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={142}
                data-line-end={143}
            >
                Based on the provided consent, personal data may be passed on,
                for advertising and marketing purposes, to the entities
                specified in the consent to the personal data processing.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={144}
                data-line-end={145}
            >
                4.7.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={145}
                data-line-end={146}
            >
                Personal data is also provided, on the basis of legal
                regulations, to the entities specified in these legal
                regulations.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={147}
                data-line-end={148}
            >
                4.8.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={148}
                data-line-end={149}
            >
                Your personal data is processed both manually and automatically,
                and is stored in both paper and electronic form.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={150}
                data-line-end={151}
            >
                4.9.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={151}
                data-line-end={152}
            >
                We are committed to storing your data securely. For this reason,
                we have implemented appropriate physical, technical and
                organisational measures, as well as plans to protect and secure
                the data that we received form you (which however does not free
                you from the obligation to take appropriate steps to secure your
                data, particularly during data transfers). The aim is to prevent
                the unlawful or unauthorised processing of your personal data,
                or the random, unauthorised or unlawful disclosure, use,
                transfer, processing, copying, transmission, modification, loss
                or damage of your data. Despite all efforts to abide by the
                rules stipulated in the relevant legal regulations, it is not
                possible to ensure the security of your data if it is
                transmitted in an unsecured manner.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={153}
                data-line-end={154}
            >
                4.10.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={154}
                data-line-end={155}
            >
                If you have chosen a password that shall allow you access to
                certain parts of the DigitalMenu Application, then you are
                responsible for keeping this password confidential. We ask you
                not to disclose this password to anyone.
            </p>
            <h1
                className="code-line text-2xl font-medium text-indigo-600 mb-3"
                data-line-start={156}
                data-line-end={157}
            >
                5. How long do we keep Personal Data?
            </h1>
            <p
                className="has-line-data text-indigo-500 mb-2"
                data-line-start={158}
                data-line-end={159}
            >
                We only keep Personal Data for the time strictly necessary for
                the legitimate business purposes mentioned above, i.e:
            </p>
            <ul className="list-disc ml-8 mb-3">
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={159}
                    data-line-end={160}
                >
                    If you are a Client who subscribed to our Services or an
                    Establishment using our Solution, or a Customer using our
                    our Application: 5 (five) years from the collection of
                    Personal Data, then in intermediate archiving with punctual
                    and restricted access for a maximum period of 10 (ten)
                    years, in accordance with accounting regulations
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={160}
                    data-line-end={161}
                >
                    If you have sent us a request for information or a quote via
                    our Site or email: 3 (three) years from the date of receipt
                    of your request
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={161}
                    data-line-end={162}
                >
                    If you have applied for a job with us: 2 (two) years from
                    the date of receipt of your application if it is not
                    accepted
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={162}
                    data-line-end={164}
                >
                    If you have sent us a request to exercise your rights: 5
                    (five) years from receipt of your request
                </li>
            </ul>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={164}
                data-line-end={165}
            >
                We will take all reasonable steps to destroy, or erase from our
                systems, all Personal Data that we no longer require and follow
                all applicable records retention schedules and policies.
            </p>
            <h1
                className="code-line text-2xl font-medium text-indigo-600 mb-3"
                data-line-start={166}
                data-line-end={167}
            >
                6. Rights of the User as the data subject
            </h1>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={167}
                data-line-end={168}
            >
                6.1.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-2"
                data-line-start={168}
                data-line-end={169}
            >
                Based on the rules set forth in the GDPR, the User has, in
                particular, the right:
            </p>
            <ul className="list-disc ml-8 mb-4">
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={169}
                    data-line-end={170}
                >
                    to access their personal data
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={170}
                    data-line-end={171}
                >
                    to the correction of the personal data, or a restriction of
                    its processing
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={171}
                    data-line-end={172}
                >
                    to the deletion of the personal data
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={172}
                    data-line-end={173}
                >
                    to raise an objection against the personal data processing
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={173}
                    data-line-end={174}
                >
                    to the portability of their personal data
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={174}
                    data-line-end={175}
                >
                    to revoke their consent to the personal data processing
                </li>
                <li
                    className="has-line-data text-indigo-500 mb-2"
                    data-line-start={175}
                    data-line-end={177}
                >
                    to file a complaint with the Office for Personal Data
                    Protection, if they believe that the processing breached
                    their right to the protection of the personal data during
                    its processing, or a related legal regulation
                </li>
            </ul>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={177}
                data-line-end={178}
            >
                6.2.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={178}
                data-line-end={179}
            >
                The exercise of these rights is done directly by sending an
                email to{" "}
                <a
                    href="mailto:support@digital-menu.ai"
                    target="_blank"
                    rel="noreferrer"
                    className=" text-indigo-600 underline"
                >
                    support@digital-menu.ai
                </a>
                . In order to verify the identity of the applicant, we ask you
                to attach a copy of your identity card to the request to
                exercise your rights.
            </p>
            <p
                className="has-line-data text-indigo-500 mb-3"
                data-line-start={180}
                data-line-end={181}
            >
                We commit ourselves to communicate the information requested
                under the conditions set out above, within a maximum of 1 (one)
                month from the receipt of the request. This period may be
                extended by a maximum of 1 (one) month if the request is complex
                or requires further study.
            </p>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={182}
                data-line-end={183}
            >
                In case of extension of the deadline for processing the request,
                the applicant has the possibility to ask us to freeze the use of
                his Personal Data, during the deadline for processing the
                request.
            </p>
            <h1
                className="code-line text-2xl font-medium text-indigo-600 mb-3"
                data-line-start={184}
                data-line-end={185}
            >
                7. With whom is the Personal Data shared?
            </h1>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={186}
                data-line-end={187}
            >
                7.1.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={187}
                data-line-end={188}
            >
                We may only send the Personal Data we hold to persons
                intervening within the framework of our activity. That includes
                our staff and any of its affiliates, notably in the sales, IT,
                client services, accounting departments, if the recipient has a
                job-related need to know the information and the transfer
                complies with any applicable cross-border transfer restrictions.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={189}
                data-line-end={190}
            >
                7.2.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={190}
                data-line-end={191}
            >
                We may subcontract certain Personal Data processing activities.
                Beforehand, we have ensured that all of these service providers
                present the appropriate guarantees, particularly in terms of
                Personal Data protection.
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={192}
                data-line-end={193}
            >
                7.3.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-2"
                data-line-start={193}
                data-line-end={195}
            >
                We may only share the Personal Data we hold with third parties
                such as our service providers if all the following conditions
                apply:
                <br />
                They have a need to know the information for the purposes of
                providing the contracted services. Sharing the Personal Data
                complies with the privacy notice provided to you, the data
                subject, and, if required, your consent has been obtained. They
                have agreed to comply with the required data security policies
                and procedures and to put adequate security measures in place.
            </p>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={196}
                data-line-end={197}
            >
                The transfer complies with any applicable cross border transfer
                restrictions
            </p>
            <h5
                className="code-line text-xl font-medium text-indigo-600 mb-1"
                data-line-start={198}
                data-line-end={199}
            >
                7.4.
            </h5>
            <p
                className="has-line-data text-indigo-500 mb-2"
                data-line-start={199}
                data-line-end={200}
            >
                If a Client requests us to share your personal information
                (email address, contact information, etc.) for the purposes of
                marketing or commercial communications, DigitalMenu may do so
                solely at its election, but only under the condition that you
                expressly consent to the sharing of this information and only if
                you are able to review the Client’s Privacy Policy.
            </p>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={201}
                data-line-end={202}
            >
                To date, there are external services responsible for fulfilling
                our social, accounting and tax obligations as well as our
                technical service providers (such as Google Analytics). We
                provide you with a list of our subcontractors. In case of
                change, we will inform you in advance.
            </p>
            <h1
                className="code-line text-2xl font-medium text-indigo-600 mb-3"
                data-line-start={203}
                data-line-end={204}
            >
                8. To whom should you address your requests or complaints?
            </h1>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={205}
                data-line-end={207}
            >
                If you have any questions or complaints regarding the processing
                carried out or the exercise of your rights, you can contact us
                at any time by email:{" "}
                <a
                    href="mailto:support@digital-menu.ai"
                    target="_blank"
                    rel="noreferrer"
                    className=" text-indigo-600 underline"
                >
                    support@digital-menu.ai
                </a>
                .<br />
                You can also contact your national data protection agency.
            </p>
            <h1
                className="code-line text-2xl font-medium text-indigo-600 mb-3"
                data-line-start={208}
                data-line-end={209}
            >
                10. Changes to our Privacy Policy
            </h1>
            <p
                className="has-line-data text-indigo-500 mb-4"
                data-line-start={209}
                data-line-end={210}
            >
                We reserve the right to modify our Privacy Policy, in particular
                in order to take into account any changes in the Regulations.
                Any changes will be subject to your prior approval.
            </p>
            <h3
                className="code-line text-2xl font-medium text-indigo-600 mb-3"
                data-line-start={211}
                data-line-end={212}
            >
                Contact us
            </h3>
            <p
                className="has-line-data text-indigo-500 mb-10"
                data-line-start={213}
                data-line-end={214}
            >
                If you have any questions or concerns about our use of cookies
                or polices, please contact us at{" "}
                <a
                    href="mailto:support@digital-menu.ai"
                    target="_blank"
                    rel="noreferrer"
                    className=" text-indigo-600 underline"
                >
                    support@digital-menu.ai
                </a>
                .
            </p>
        </div>
    );
};
