import { CheckIcon } from "@heroicons/react/20/solid";
import React from "react";
import { classNames } from "../helpers";
import { FormattedMessage } from "react-intl";

const steps = [
    {
      name: <FormattedMessage id="fillOutTheForm" />,
      description: "",
      status: "current",
    },
    {
      name: <FormattedMessage id="getAllDetails" />,
      description: "",
      status: "upcoming",
    },
    {
      name: <FormattedMessage id="createYourDM" />,
      description: "",
      status: "upcoming",
    },
    {
      name: <FormattedMessage id="improveYourBusiness" />,
      description: "",
      status: "upcoming",
    },
  ];

export const FeedbackStepper = () => {
    return (
        <nav aria-label="Progress">
            <ol className="overflow-hidden">
                {steps.map((step, stepIdx) => (
                    <li
                        key={step.name}
                        className={classNames(
                            stepIdx !== steps.length - 1 ? "pb-10" : "",
                            "relative"
                        )}
                    >
                        {step.status === "complete" ? (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div
                                        className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600"
                                        aria-hidden="true"
                                    />
                                ) : null}
                                <div className="group relative flex items-center">
                                    <span className="flex h-9 items-center">
                                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                                            <CheckIcon
                                                className="h-5 w-5 text-white"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium">
                                            {step.name}
                                        </span>
                                    </span>
                                </div>
                            </>
                        ) : step.status === "current" ? (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div
                                        className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                                        aria-hidden="true"
                                    />
                                ) : null}
                                <div
                                    className="group relative flex items-center"
                                    aria-current="step"
                                >
                                    <span
                                        className="flex h-9 items-center"
                                        aria-hidden="true"
                                    >
                                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                                            <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium text-indigo-600">
                                            {step.name}
                                        </span>
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div
                                        className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                                        aria-hidden="true"
                                    />
                                ) : null}
                                <div className="group relative flex items-center">
                                    <span
                                        className="flex h-9 items-center"
                                        aria-hidden="true"
                                    >
                                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                            <span className="h-2.5 w-2.5 rounded-full bg-transparent " />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium text-gray-500">
                                            {step.name}
                                        </span>
                                    </span>
                                </div>
                            </>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};
