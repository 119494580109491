import { useContext, useEffect, useState } from "react";
import { BaseBanner, ChooseBanner } from "../UI";
import { CookiesContext } from "../../../context";
import { classNames } from "../../../helpers";

export const Cookies = () => {
    const { cookiesData } = useContext(CookiesContext);
    const [isMobile, setIsMobile] = useState(document.body.clientWidth < 445);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setIsMobile(document.body.clientWidth < 445);
        });

        return () => {
            window.removeEventListener("resize", () => {
                setIsMobile(document.body.clientWidth < 445);
            });
        };
    }, []);

    return (
        <div
            className={classNames(
                "fixed z-50 bottom-20 max-w-[420px]",
                isMobile ? "right-0" : "right-5",
                cookiesData.open ? "custom-slide-in" : "hidden",
                cookiesData.animationClose && "custom-slide-out"
            )}
        >
            <div className="relative bg-white flex flex-col rounded-lg shadow-md transition-all">
                {cookiesData.step === 1 && <BaseBanner />}
                {cookiesData.step === 2 && <ChooseBanner />}
            </div>
        </div>
    );
};
