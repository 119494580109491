/* eslint-disable jsx-a11y/alt-text */
import devices from "../assets/adminBlock.png";
import { RoughNotation } from "react-rough-notation";
import { FormattedMessage } from "react-intl";

export default function CTA() {
  return (
    <div className="cta-wrapper bg-indigo-500">
      <div className=" rounded-none cta-img wow fadeInDown w-[100%] sm:w-[80%] md:w-[60%] lg:w-[65%] xl:w-[50%]">
        <img src={devices} alt="" />
      </div>
      <div className="flex flex-row">
        <div className="px-2 sm:px-6 md:px-8 lg:px-10">
          <div className="text-wrapper" style={{ fontFamily: "Open Sans" }}>
            <div className="text-white mb-34">
              <RoughNotation
                type="underline"
                order="1"
                color="#fff"
                show={true}
                padding="10px"
              >
                <span className="sub-title st-one">
                  <FormattedMessage id="adminPanel" />
                </span>
              </RoughNotation>
              <h2
                className="cta-text mt-[40px] max-w-[80%] md:max-w-[60%]"
                style={{ fontFamily: "Open Sans" }}
              >
                <FormattedMessage id="managingYourMenu" />{" "}
                <span className="fill-text">
                  <FormattedMessage id="simpler" />
                </span>{" "}
                <FormattedMessage id="withA" />
                <span className="fill-text">
                  {" "}
                  <FormattedMessage id="modern" />
                </span>
                ,{" "}
                <span className="fill-text">
                  <FormattedMessage id="multifunctional" />
                </span>{" "}
                <FormattedMessage id="panel" />
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
