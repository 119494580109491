import { Helmet } from "react-helmet";

import { Cookies } from "./Cookies";
import { stages } from "../../../constants";
import { useContext, useEffect } from "react";
import { CookiesContext } from "../../../context";

export const AnalyticsBlock = () => {
  const currentStage = process.env.REACT_APP_STAGE || "";
  const { cookiesData } = useContext(CookiesContext);

  useEffect(() => {
    localStorage.setItem("dm-cookie", JSON.stringify(cookiesData));
  }, [cookiesData]);

  return stages[currentStage] === "prod" ? (
    <>
      <Helmet>
        {cookiesData.cookies.find((service) => service.id === "meta-pixel")
          ?.choose &&
          cookiesData.confirmed && (
            <script>
              {`      !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
          );
          fbq("init", "1302695227088601");
          fbq("track", "PageView");`}
            </script>
          )}
        {cookiesData.cookies.find((service) => service.id === "meta-pixel")
          ?.choose &&
          cookiesData.confirmed && (
            <noscript>
              {`<img
          height="1"
          width="1"
          style="display: none"
          src="https://www.facebook.com/tr?id=1302695227088601&ev=PageView&noscript=1"
        />`}
            </noscript>
          )}
        {cookiesData.cookies.find(
          (service) => service.id === "google-analytics"
        )?.choose &&
          cookiesData.confirmed && (
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-QR2TGB8RPQ"
            ></script>
          )}
        {cookiesData.cookies.find(
          (service) => service.id === "google-analytics"
        )?.choose &&
          cookiesData.confirmed && (
            <script>
              {` window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
          gtag("js", new Date());

          gtag("config", "G-QR2TGB8RPQ");`}
            </script>
          )}
      </Helmet>
      <Cookies />
    </>
  ) : (
    <>
      <Helmet>
        {cookiesData.cookies.find((service) => service.id === "meta-pixel")
          ?.choose &&
          cookiesData.confirmed && (
            <script>
              {`
              console.log('pixel on')
              `}
            </script>
          )}
        {cookiesData.cookies.find(
          (service) => service.id === "google-analytics"
        )?.choose &&
          cookiesData.confirmed && (
            <script>
              {`
              console.log('google analytics on')
              `}
            </script>
          )}
      </Helmet>
      <Cookies />
    </>
  );
};
