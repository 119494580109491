import { useEffect, useRef, useState } from "react";
import phone from "../assets/phoneWithScreen.png";

export default function PhoneHero({ containerRef, sliderRef }) {
    const phoneRef = useRef();

    // Велосипед для получения useState в eventListener
    const [scrollPos, _setScrollPos] = useState(false);
    const scrollPosRef = useRef(scrollPos);
    const setScrollPos = (data) => {
        scrollPosRef.current = data;
        _setScrollPos(data);
    };

    const [sliderTop, _setSliderTop] = useState(false);
    const sliderTopRef = useRef(sliderTop);
    const setSliderTop = (data) => {
        sliderTopRef.current = data;
        _setSliderTop(data);
    };

    const [prevScroll, _setPrevScroll] = useState(0);
    const prevScrollRef = useRef(sliderTop);
    const setPrevScroll = (data) => {
        prevScrollRef.current = data;
        _setPrevScroll(data);
    };

    const handleScroll = (event) => {
        const sliderPos = sliderRef.current.getBoundingClientRect();
        const phonePos = phoneRef.current.getBoundingClientRect();

        if (window.scrollY >= prevScrollRef.current) {
            setPrevScroll(window.scrollY);
            if (phonePos.top > 0 && sliderPos.top / 2 <= phonePos.top) {
                if (!scrollPosRef.current && !sliderTopRef.current) {
                    setScrollPos(window.scrollY);
                    setSliderTop(sliderPos.top);
                }
            } else if (
                scrollPosRef.current &&
                sliderTopRef.current &&
                window.scrollY < scrollPosRef.current + phonePos.top &&
                phoneRef.current.style.position !== "absolute"
            ) {
                setScrollPos(false);
                setSliderTop(false);
            }
        } else {
            setPrevScroll(window.scrollY);
            if (
                phoneRef.current.style.position === "absolute" &&
                window.scrollY + sliderTopRef.current / 2 <=
                    scrollPosRef.current + sliderTopRef.current / 2
            ) {
                setScrollPos(false);
                setSliderTop(false);
            }
        }
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", handleScroll);
        }

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (scrollPos && sliderTop) {
            phoneRef.current.style.position = "absolute";
            phoneRef.current.style.top = `${scrollPos + sliderTop / 2 - 220}px`;
        } else {
            phoneRef.current.style.position = "fixed";
            phoneRef.current.style.top = "0";
        }
    }, [scrollPos, sliderTop]);

    return (
        <img
            ref={phoneRef}
            className="wow slideInRight hidden lg:block fixed top-0 h-96 w-full sm:h-72 md:h-96 lg:right-0 lg:h-4/6 lg:w-[44%] mb-20 lg:mt-44 object-contain z-20"
            src={phone}
            alt=""
        />
    );
}
