/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { RoughNotation } from "react-rough-notation";

export default function FAQ() {
  const [activeIndex, setActiveIndex] = useState();

  const data = [
    {
      title: <FormattedMessage id="alreadyHaveMenus" />,
      content: <FormattedMessage id="menuIsContactless" />,
      id: 1,
    },
    {
      title: <FormattedMessage id="QRcodeMenu" />,
      content: <FormattedMessage id="PDFfileNotInteractive" />,
      id: 2,
    },
    {
      title: <FormattedMessage id="haveToCall" />,
      content: <FormattedMessage id="ableToEdit" />,
      id: 3,
    },
    {
      title: <FormattedMessage id="possibleToTry" />,
      content: <FormattedMessage id="tryOurDigitalMenu" />,
      id: 4,
    },
    {
      title: <FormattedMessage id="wantToChange" />,
      content: <FormattedMessage id="QRcodesWillWork" />,
      id: 5,
    },
  ];

  useEffect(() => {
    if (activeIndex || activeIndex === 0) {
      const panels = document.querySelectorAll(".ac-panel");
      panels.forEach((el, i) => {
        if (i === activeIndex) {
          el.style.height = el.scrollHeight + "px";
        } else {
          el.style.height = "0px";
        }
      });
    }
  }, [activeIndex]);

  return (
    <section
      className="testimonial-area testimonial-area-v1 dark-blue-bg pattern-bg pt-130 pb-12 overflow-x-hidden"
      id="testimonial"
    >
      <div className="relative z-[1] mx-auto max-w-7xl py-10 px-4 mt-4 sm:px-6 lg:px-8">
        <div className="flex flex-row">
          <div className="text-white mb-55">
            <RoughNotation
              type="underline"
              order="1"
              color="#fff"
              show={true}
              padding="10px"
            >
              <span className="st-one">
                <FormattedMessage id="questons" />
              </span>
            </RoughNotation>
            <h2 className="sub-title font-bold mt-6 mb-12">Digital menu FAQ</h2>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="accordion" id="accordion">
              {data.map((elem, index) => (
                <div
                  className={`ac js-enabled`}
                  key={'faq-' + elem.id}
                  id={`ac-${index}`}
                >
                  <h2 className="ac-header">
                    <button
                      type="button"
                      className="ac-trigger text-indigo-700 text-base md:text-lg"
                      id={`ac-trigger-${index}`}
                      aria-controls={`ac-panel-${index}`}
                      aria-disabled="false"
                      aria-expanded="false"
                      onClick={() => {
                        activeIndex === index
                          ? setActiveIndex()
                          : setActiveIndex(index);
                      }}
                    >
                      {elem.title}
                    </button>
                  </h2>
                  <div
                    className={`ac-panel text-base md:text-lg`}
                    id={`ac-panel-${index}`}
                    role="region"
                    aria-labelledby={`ac-trigger-${index}`}
                    style={{
                      transition: "0.3s ease-in-out",
                    }}
                  >
                    <p className="ac-text">{elem.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
