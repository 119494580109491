/* eslint-disable jsx-a11y/alt-text */
import {
  ClockIcon,
  GlobeEuropeAfricaIcon,
  LanguageIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  QrCodeIcon,
} from "@heroicons/react/24/outline";
import phone from "../assets/phoneBlock.png";
import ipad from "../assets/ipadBlock.png";
import happyEmoji from "../assets/happy_emoji.png";
import happy from "../assets/happy.jpg";
import { FormattedMessage } from "react-intl";

export default function Sell() {
  return (
    <div className="bg-gray-100 py-24 sm:py-32 lg:py-40 relative overflow-x-hidden">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="sm:text-center">
          <p className="wow fadeIn mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            <FormattedMessage id="createdSmartMenu" />
          </p>
          <p
            className="wow fadeIn mx-auto mt-6 max-w-2xl text-2xl leading-8 text-gray-500"
            style={{ fontFamily: "Open Sans" }}
          >
            <FormattedMessage id="discoverTheBenefits" />{" "}
            <b>
              <FormattedMessage id="recommendingSystems" />
            </b>{" "}
            <FormattedMessage id="analyzeYourCustomers" />
          </p>
        </div>

        <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
          <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
            <div className="flex flex-col items-start wow slideInLeft rounded-md p-6 ">
              <div className="relative flex flex-row gap-6 mb-3">
                <div className="sm:min-w-0 sm:flex-1">
                  <p className="text-lg font-medium leading-8 text-gray-900">
                    <b>
                      <FormattedMessage id="forTheClient" />
                    </b>{" "}
                    - <FormattedMessage id="selectionRecommendations" />
                  </p>
                </div>
              </div>
              <div className="relative flex flex-row gap-6 mb-3">
                <div className="sm:min-w-0 sm:flex-1">
                  <p className="text-lg font-normal leading-8 text-gray-500">
                    <FormattedMessage id="iCanOrder" />
                  </p>
                </div>
              </div>

              <img
                className="object-contain my-8 relative bg-center p-4 max-w-80 w-[60%] self-center"
                src={phone}
              ></img>
            </div>
            <div className="flex flex-col wow slideInRight rounded-md p-6 ">
              <div className="relative flex flex-row gap-6 mb-3">
                <div className="sm:min-w-0 sm:flex-1">
                  <p className="text-lg font-medium leading-8 text-gray-900">
                    <b>
                      <FormattedMessage id="forYou" />
                    </b>{" "}
                    - <FormattedMessage id="analytics" />
                  </p>
                </div>
              </div>
              <div className="relative flex flex-row gap-6 mb-3">
                <div className="sm:min-w-0 sm:flex-1">
                  <p className="text-lg font-normal leading-8 text-gray-500">
                    <FormattedMessage id="analyticsAboutWhich" />
                  </p>
                </div>
              </div>
              <img
                className="object-contain my-8 relative bg-center p-4 max-w-80 w-[100%]"
                src={ipad}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
