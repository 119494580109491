import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import Parallax from "parallax-js";
import { useContext, useEffect, useRef } from "react";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import logo from "../assets/digital-menu.logo-01.svg";
import { FormattedMessage } from "react-intl";
import { CookiesContext } from "../context";
import { openCookieBanner } from "../components/Cookies/actions";

export default function Footer() {
  const { cookiesData, setCookiesData } = useContext(CookiesContext);
  return (
    <footer className="footer-area bg-gray-50 overflow-x-hidden">
      <div className="relative z-[1] mx-auto max-w-7xl py-10 px-4 mt-4 sm:px-6 lg:px-8">
        <div className="footer-top pt-8 pb-8">
          <div className="flex flex-col sm:flex-row align-items-center justify-center">
            <div className="w-[100%] lg:w-[40%]">
              <div className="footer-logo wow fadeInLeft">
                <img className="footer-img" src={logo} alt="Brand Logo" />
              </div>
            </div>
            <div className="w-[100%] lg:w-[40%] h-auto">
              <div className="text-wrapper wow fadeInRight">
                <h3 className="text-3xl font-bold">
                  Digital menu
                  <br />
                  <span className="text-indigo-600">for businesses</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="flex flex-col lg:flex-row">
            <div className="w-[100%] lg:w-[50%]">
              <div
                className="widget social-widget mb-10 wow fadeInUp social-links"
                data-wow-delay=".25s"
              >
                <h4 className="text-lg font-bold mr-4">
                  <FormattedMessage id="follow" />
                </h4>
                <ul className="social-nav">
                  <li>
                    <a
                      href="https://instagram.com/digital_menu.ai"
                      target="_blank"
                      className="flex flex-row align-center text-gray-500"
                      rel="noreferrer"
                    >
                      <AiFillInstagram
                        className="text-indigo-600"
                        style={{
                          marginRight: "10px",
                          fontSize: "22px",
                        }}
                      />
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=100094227724744"
                      target="_blank"
                      className="flex flex-row align-center text-gray-500"
                      rel="noreferrer"
                    >
                      <AiFillFacebook
                        className="text-indigo-600"
                        style={{
                          marginRight: "10px",
                          fontSize: "22px",
                        }}
                      />
                      Facebook
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-[100%] lg:w-[50%]">
              <div className="flex flex-row flex-wrap justify-center lg:justify-end">
                <ul className="mr-4">
                  <li>
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      className="text-gray-500"
                    >
                      Privacy & Cookie
                    </a>
                  </li>
                </ul>
                <ul className="mr-4">
                  <li>
                    <a href="/terms" target="_blank" className="text-gray-500">
                      Tems & conditions
                    </a>
                  </li>
                </ul>
                {/* <ul>
                  <li>
                    <a
                      className="text-primary"
                      href="javascript:openAxeptioCookies()"
                    >
                      Update your cookies settings
                    </a>
                  </li>
                </ul> */}
                <ul>
                  <li>
                    <button
                      className={
                        "text-primary"
                      }
                      // eslint-disable-next-line no-script-url
                      onClick={() => {
                        openCookieBanner({ setCookiesData });
                      }}
                    >
                      Update your cookies settings
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
