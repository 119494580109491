import {
  CloudIcon,
  LockClosedIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { FormattedMessage } from "react-intl";

const features = [
  {
    name: <FormattedMessage id="cloudStorage" />,
    description:
    <FormattedMessage id="nowadays" />,
    icon: CloudIcon,
    id: 1,
  },
  {
    name: <FormattedMessage id="encryption" />,
    description:
    <FormattedMessage id="identifyingDataIsEncrypted" />,
    icon: LockClosedIcon,
    id: 2,
  },
  {
    name: <FormattedMessage id="securityAudit" />,
    description:
    <FormattedMessage id="versionUpdates" />,
    icon: MagnifyingGlassIcon,
    id: 3,
  },
];

export default function SecurityFeatures() {
  return (
    <div className="bg-white py-20 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">
          <FormattedMessage id="betterWay" />
        </h2>
        <dl className="grid grid-cols-1 gap-16 lg:grid lg:grid-cols-3">
          {features.map((feature) => (
            <div key={'features-sec-' + feature.id}>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-500 text-white">
                  <feature.icon className="h-8 w-8" aria-hidden="true" />
                </div>
                <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
