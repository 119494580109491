import { CheckIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { FeedbackFormModal } from "../components/FeedbackFormModal";
import { trackEvent } from "../helpers";
import { FormattedMessage } from "react-intl";

const features = [
  {
    name: <FormattedMessage id="createNewItems" />,
    description: <FormattedMessage id="manyItems" />,
    id: 1,
  },
  {
    name: <FormattedMessage id="archive" />,
    description: <FormattedMessage id="serveSeasonal" />,
    id: 2,
  },
  {
    name: <FormattedMessage id="soldoutStatus" />,
    description: <FormattedMessage id="changingTheStatus" />,
    id: 3,
  },
  {
    name: <FormattedMessage id="dashboard" />,
    description: <FormattedMessage id="exploreRealTime" />,
    id: 4,
  },
  {
    name: <FormattedMessage id="anyDevice" />,
    description: <FormattedMessage id="fromAnyBrowser" />,
    id: 5
  },
  {
    name: <FormattedMessage id="availableHours" />,
    description: <FormattedMessage id="easilyManage" />,
    id: 6,
  },
  {
    name: <FormattedMessage id="managePhotos" />,
    description: <FormattedMessage id="updateYourPhotos" />,
    id: 7,
  },
  {
    name: <FormattedMessage id="reporting" />,
    description: <FormattedMessage id="adminPanelWillOffer" />,
    id: 8,
  },
];

export default function Features({
  setShowToast,
  setMessageToast,
  setTitleToast,
  setTypeToast,
  successSended,
  setSuccessSended,
}) {
  const [showFeedback, setShowFeedback] = useState(false);
  return (
    <div className="bg-white pt-44 overflow-x-hidden" id="features">
      <FeedbackFormModal
        open={showFeedback}
        setOpen={setShowFeedback}
        setShowToast={setShowToast}
        setTitleToast={setTitleToast}
        setMessageToast={setMessageToast}
        setTypeToast={setTypeToast}
        successSended={successSended}
        setSuccessSended={setSuccessSended}
      />
      <div className="mx-auto max-w-7xl py-2 px-[40px] sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:py-24 lg:px-8">
        <div>
          <h2 className="text-lg font-semibold text-indigo-600">
            <FormattedMessage id="everythingYouNeed" />
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">
            <FormattedMessage id="allInOne" />
          </p>
          <p className="mt-4 text-lg text-gray-500">
            <FormattedMessage id="creatingYourFirstMenu" />
          </p>
          <button
            onClick={() => {
              trackEvent("trackCustom", "ClickFreeTrial", {
                pagePlace: "features",
              });
              setShowFeedback(true);
            }}
            className="mt-6 w-[60%] transition-colors duration-300 flex items-center justify-center rounded-md border border-transparent bg-[rgba(255,255,255,0.4)] px-8 py-3 text-base font-normal text-indigo-600 md:hover:bg-white md:py-4 md:px-10 md:text-lg border-2 border-indigo-600 md:hover:bg-indigo-500 md:hover:text-white"
          >
            <FormattedMessage id="bookDemo" />
          </button>
        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0">
          <dl className="space-y-10 sm:grid sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-4 sm:gap-x-6 sm:gap-y-10 sm:space-y-0 lg:gap-x-8">
            {features.map((feature) => (
              <div key={'features-' + feature.id} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green-500"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg font-medium leading-6 text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
