/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import card from "../assets/card.png";
import paper from "../assets/paper.png";
import shape from "../assets/shape.png";
import stamp from "../assets/stamp.png";
import sticker from "../assets/sticker.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { FormattedMessage } from "react-intl";

const data = [
    {
        title: <FormattedMessage id="stickers" />,
        img: sticker,
        id: 1,
    },
    {
        title: <FormattedMessage id="cards" />,
        img: card,
        id: 2,
    },
    {
        title: <FormattedMessage id="stamp" />,
        img: stamp,
        id: 3,
    },
    {
        title: <FormattedMessage id="shape" />,
        img: shape,
        id: 4,
    },
    {
        title: <FormattedMessage id="paper" />,
        img: paper,
        id: 5,
    },
];

export default function Products() {
    return (
        <div className="relative bg-white overflow-x-hidden">
            <h2 className="w-full p-16 text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
               <FormattedMessage id="yourDigitalQR" />
            </h2>
            <main className="lg:relative pb-16 relative">
                {window.innerWidth >= 720 ? (
                    <div className="mt-4 flow-root px-10">
                        <div className="-my-2">
                            <div className="relative box-content h-80 overflow-x-auto scrollbar py-2 xl:overflow-visible">
                                <div className="min-w-screen-xl absolute flex space-x-8 px-4 sm:px-6 lg:px-8 xl:relative xl:grid xl:grid-cols-5 xl:gap-x-8 xl:space-x-0 xl:px-0">
                                    {data.map((item) => (
                                        <div
                                            key={'products-' + item.id}
                                            className="relative flex h-80 w-56 flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto"
                                        >
                                            <span
                                                aria-hidden="true"
                                                className="absolute inset-0"
                                            >
                                                <img
                                                    src={item.img}
                                                    alt=""
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </span>
                                            <span
                                                aria-hidden="true"
                                                className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                                            />
                                            <span className="relative mt-auto text-center text-xl font-bold text-white">
                                                {item.title}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={true}
                        modules={[Pagination]}
                    >
                        {data.map((elem) => (
                            <SwiperSlide key={"slide-" + elem.id}>
                                <div className="flex flex-col items-center pb-12">
                                    <img
                                        style={{
                                            transition: "ease-in 0.3s",
                                        }}
                                        className="wow slideInRight h-[340px] w-full object-cover"
                                        src={elem.img}
                                        alt=""
                                    />
                                    <p className="mt-3 font-bold text-gray-600">
                                        {elem.title}
                                    </p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
                {/* <p className="w-full px-16 text-center pt-12 text-lg font-medium tracking-tight text-gray-900 sm:text-2xl">
          It can be ordered from us or done yourself
        </p>
        <a
          href="#"
          className="mt-6 w-[220px] mx-auto transition-colors duration-300 flex items-center justify-center rounded-md border border-transparent bg-[rgba(255,255,255,0.4)] px-8 py-3 text-base font-normal text-indigo-600 md:hover:bg-white md:py-4 md:px-10 md:text-lg border-2 border-indigo-600 md:hover:bg-indigo-500 md:hover:text-white"
        >
          Details
        </a> */}
            </main>
        </div>
    );
}
