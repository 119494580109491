import { Switch } from "@headlessui/react";
import { classNames } from "../../../helpers";
import { toogleService } from "../actions";
import { useContext } from "react";
import { CookiesContext } from "../../../context";

export const ServicesCard = ({ service }) => {
  const { cookiesData, setCookiesData } = useContext(CookiesContext);

  return (
    <div className="border-b-2 border-b-gray-300 last:border-none px-4 py-2 w-auto flex justify-between items-center text-sm text-gray-700 select-none">
      <div className="flex flex-1 self-stretch items-center cursor-pointer">
        <div className="text-center mr-2 shrink-0">
          <div>
            <img alt="" src={service.icon} className="mx-auto" />
          </div>

          <a
            href={service.infoLink}
            target="_blank"
            className=" inline-block p-[2px] bg-gray-100 text-gray-400 rounded-full h-[18px] w-[18px] text-center text-[10px] leading-[13px] font-bold"
            rel="noopener nofollower noreferrer"
          >
            ?
          </a>
        </div>
        <div>
          <div className=" text-sm">{service.name}</div>
          <div className="text-xs pr-2.5">{service.description}</div>
        </div>
      </div>
      <Switch
        checked={service.choose}
        onChange={(value) => {
          toogleService({
            cookiesData,
            setCookiesData,
            id: service.id,
            value,
          });
        }}
        className={classNames(
          service.choose ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            service.choose ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
    </div>
  );
};
