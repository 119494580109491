import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useContext } from "react";
import { LocaleContext } from "../context";
import { LanguageIcon } from "@heroicons/react/24/outline";
import { classNames } from "../helpers";

export const ChangeLanguage = ({ mini = false }) => {
  const { locale: activeLocale, setLocale: setActiveLocale } =
    useContext(LocaleContext);

  const localesDisplay = {
    en: {
      value: "en",
      label: "English",
      id: 1,
    },
    es: {
      value: "es",
      label: "Español",
      id: 2,
    },
    pt: {
      value: "pt",
      label: "Português",
      id: 3,
    },
    el: {
      value: "el",
      label: "Ελληνικά",
      id: 4,
    },
    hu: {
      value: "hu",
      label: "Magyar",
      id: 5,
    },
  };

  return (
    <Listbox value={activeLocale} onChange={setActiveLocale}>
      <div className={classNames("relative w-36")}>
        <Listbox.Button
          className={classNames(
            mini
              ? "w-auto px-2 py-2 shadow-none float-right border border-black/5"
              : "w-full px-4 py-2 pr-10 shadow-sm border border-black/5",
            "relative cursor-pointer rounded-md h-full bg-white text-base font-medium text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-ingigo-300 sm:text-sm"
          )}
        >
          {mini ? (
            <span className="pointer-events-none">
              <LanguageIcon
                className="h-6 w-6 text-gray-400"
                aria-hidden="true"
              />
            </span>
          ) : (
            <>
              <span className="block truncate">
                {localesDisplay[activeLocale].label}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </>
          )}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={classNames(
              mini ? "mt-11" : "mt-1",
              "absolute max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
            )}
          >
            {Object.values(localesDisplay).map((locale) => (
              <Listbox.Option
                key={"locale-" + locale.id}
                className={({ active }) =>
                  `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                    active ? "bg-indigo-100 text-indigo-900" : "text-gray-900"
                  }`
                }
                value={locale.value}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {locale.label}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
