import { useEffect } from "react";

export function ScrollToTopOnReload({ setLoading }) {
    useEffect(() => {
        const handleScroll = () => {
            window.scrollTo(0, 0);
        };

        // Scroll to top when the component is mounted
        handleScroll();

        // Scroll to top on window reload
        window.addEventListener("beforeunload", handleScroll);

        // Cleanup: remove the event listener when the component is unmounted
        return () => {
            window.removeEventListener("beforeunload", handleScroll);
        };
    }, []);

    return null;
}
