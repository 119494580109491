import { Dialog, Transition } from "@headlessui/react";
import {
  ChatBubbleLeftRightIcon,
  ClipboardDocumentCheckIcon,
  PresentationChartLineIcon,
  QrCodeIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment, useState } from "react";
import { phoneCodes, zoneInfo } from "../constants";
import { classNames, phoneMask, trackEvent } from "../helpers";
import { CountrySelect } from "./CountrySelect";
import { PhoneCodeSelect } from "./PhoneCodeSelect";
import { ReactComponent as FeedbackIcon } from "../assets/feedback.svg";
import { ReactComponent as FeedbackLogo } from "../assets/logo-dark.svg";
import { FeedbackStepper } from "./FeedbackStepper";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { Spinner } from "./Spinner";
import { SuccessFeedbackText } from "./SuccessFeedbackText";
import { FormattedMessage } from "react-intl";

const steps = [
  {
    name: <FormattedMessage id="fillOutTheForm" />,
    description: "",
    status: "current",
  },
  {
    name: <FormattedMessage id="getAllDetails" />,
    description: "",
    status: "upcoming",
  },
  {
    name: <FormattedMessage id="createYourDM" />,
    description: "",
    status: "upcoming",
  },
  {
    name: <FormattedMessage id="improveYourBusiness" />,
    description: "",
    status: "upcoming",
  },
];

const requestFeedback = async ({
  setLoading,
  body,
  setShowToast,
  setTitleToast,
  setTypeToast,
  setMessageToast,
  setSuccessSended,
}) => {
  try {
    setLoading(true);
    const req = await fetch(
      "https://fu6jvxm51k.execute-api.eu-central-1.amazonaws.com/default/web-form-free-trial",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    if (req.ok) {
      trackEvent("track", "SubmitApplication");
      setSuccessSended(true);
    } else {
      throw new Error("Something went wrong");
    }
  } catch (e) {
    setMessageToast("Failed to send request. Please try again later");
    setTitleToast("Error");
    setTypeToast("error");
    setShowToast(true);
  } finally {
    setLoading(false);
  }
};

export const FeedbackFormModal = ({
  open,
  setOpen,
  setShowToast,
  setTitleToast,
  setMessageToast,
  setTypeToast,
}) => {
  const [countrySelected, setCountrySelected] = useState(
    zoneInfo.find((item) => item.label === "Europe/Budapest")
  );
  const [phoneCode, setPhoneCode] = useState(
    phoneCodes.find((item) => item.code === "+36")
  );

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [restaurantName, setRestaurantName] = useState("");
  const [restaurantNameError, setRestaurantNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [successSended, setSuccessSended] = useState(false);
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const close = () => {
    if (!loading) {
      setOpen(false);
      setTimeout(() => {
        setSuccessSended(false);
        setEmail("");
        setEmailError(false);
        setPhoneNumber("");
        setPhoneNumberError(false);
        setName("");
        setNameError(false);
        setRestaurantName("");
        setRestaurantNameError(false);
      }, 250);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (!name.trim()) {
        setNameError(true);
      } else if (!restaurantName.trim()) {
        setRestaurantNameError(true);
      } else if (!phoneNumber.trim()) {
        setPhoneNumberError(true);
      } else if (!email.trim()) {
        setEmailError(true);
      } else {
        const body = {
          name: name,
          restaurant_name: restaurantName,
          phone: phoneCode.code + phoneNumber,
          country: countrySelected.country,
          email: email,
          message: message.trim(),
          is_free_trial_form: true,
          phone_country_code: phoneCode.name
        };
        requestFeedback({
          setLoading,
          body,
          setShowToast,
          setTitleToast,
          setTypeToast,
          setMessageToast,
          setSuccessSended,
        });
      }
    }
  };

  const buttonContent = () => {
    if (loading) {
      return <Spinner color="bg-primary" />;
    } else {
      return <FormattedMessage id="send" />;
    }
  };

  const stepsAndFeedbackContent = () => {
    if (!successSended) {
      return steps.map((step, index) => {
        return (
          <div
            key={"step-feedback-" + step.name}
            className="py-3 w-[110%] flex items-center px-4 justify-start bg-indigo-100 text-base -mx-4 "
          >
            {index === 0 && (
              <ClipboardDocumentCheckIcon className="h-6 w-6 text-indigo-500 mr-2" />
            )}
            {index === 1 && (
              <ChatBubbleLeftRightIcon className="h-6 w-6 text-indigo-500 mr-2" />
            )}
            {index === 2 && (
              <QrCodeIcon className="h-6 w-6 text-indigo-500 mr-2" />
            )}
            {index === 3 && (
              <PresentationChartLineIcon className="h-6 w-6 text-indigo-500 mr-2" />
            )}
            {step.name}
          </div>
        );
      });
    } else {
      return (
        <div
          className={classNames(
            successSended ? "w-[100%]" : "w-[43%]",
            "transition-all p-4 bg-indigo-100 rounded-l-lg flex-col items-center flex"
          )}
        >
          <XMarkIcon
            onClick={close}
            className={classNames(
              !successSended && "hidden",
              "h-6 w-6 cursor-pointer md:hover:text-gray-500 absolute top-2 right-2"
            )}
            aria-hidden="true"
          />
          <FeedbackLogo
            className={classNames(
              successSended ? "w-[30%]" : "w-[50%]",
              "mb-4"
            )}
          />
          <FeedbackIcon
            className={classNames(successSended ? "hidden" : "w-[70%]", "mb-6")}
          />
          {successSended ? <SuccessFeedbackText /> : <FeedbackStepper />}
        </div>
      );
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40"
          onClose={() => {
            setOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-auto rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                  <div className="flex rounded-lg">
                    <div
                      className={classNames(
                        successSended ? "w-[100%]" : "w-[43%]",
                        "transition-all min-h-[590px] duration-300 p-4 bg-indigo-100 rounded-l-lg flex-col items-center justify-center hidden sm:flex"
                      )}
                    >
                      <XMarkIcon
                        onClick={close}
                        className={classNames(
                          !successSended && "hidden",
                          "h-6 w-6 cursor-pointer md:hover:text-gray-500 absolute top-2 right-2"
                        )}
                        aria-hidden="true"
                      />
                      <FeedbackLogo
                        className={classNames(
                          successSended ? "w-[30%]" : "w-[50%]",
                          "mb-4 transition-all duration-300"
                        )}
                      />
                      <FeedbackIcon
                        className={classNames(
                          successSended ? "hidden" : "w-[70%]",
                          "mb-6 transition-all duration-300"
                        )}
                      />
                      {successSended ? (
                        <div className="w-[80%]">
                          <SuccessFeedbackText />
                        </div>
                      ) : (
                        <FeedbackStepper />
                      )}
                    </div>
                    <div
                      className={classNames(
                        successSended
                          ? "w-full sm:w-[0px] p-0 overflow-hidden"
                          : "w-full sm:w-[57%]",
                        "p-4 transition-all duration-300 bg-white rounded-lg sm:rounded-r-lg"
                      )}
                    >
                      <div
                        className={classNames(
                          successSended && "hidden",
                          "flex w-full items-center justify-between mb-6"
                        )}
                      >
                        <h1 className="text-lg">
                          <FormattedMessage id="requestAccess" />
                        </h1>
                        <XMarkIcon
                          onClick={() => {
                            setOpen(false);
                          }}
                          className="h-6 w-6 cursor-pointer md:hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </div>

                      <div
                        className={classNames(
                          successSended && "mb-0",
                          "flex-col w-full mb-4 flex sm:hidden"
                        )}
                      >
                        {stepsAndFeedbackContent()}
                      </div>

                      <form
                        onSubmit={handleSubmit}
                        className={classNames(
                          successSended && "hidden",
                          "flex w-full flex-col justify-between h-auto sm:h-[90%]"
                        )}
                      >
                        <div className="mb-5">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            <FormattedMessage id="name" />
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <input
                              type="text"
                              name="name"
                              required
                              value={name}
                              onChange={(e) => {
                                setNameError(false);
                                setName(e.target.value);
                              }}
                              id="name"
                              className={`${
                                nameError
                                  ? "ring-1 ring-inset ring-red-300"
                                  : "focus:border-indigo-500 focus:ring-indigo-500"
                              } block w-full rounded-md border-gray-300 shadow-sm sm:text-sm`}
                            />
                            {nameError && (
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <ExclamationCircleIcon
                                  className="h-5 w-5 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mb-5">
                          <label
                            htmlFor="restaurant-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            <FormattedMessage id="restaurantName" />
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <input
                              type="text"
                              required
                              value={restaurantName}
                              onChange={(e) => {
                                setRestaurantNameError(false);
                                setRestaurantName(e.target.value);
                              }}
                              name="restaurant-name"
                              id="restaurant-name"
                              className={`${
                                restaurantNameError
                                  ? "ring-1 ring-inset ring-red-300"
                                  : "focus:border-indigo-500 focus:ring-indigo-500"
                              } block w-full rounded-md border-gray-300 shadow-sm sm:text-sm`}
                            />
                            {restaurantNameError && (
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <ExclamationCircleIcon
                                  className="h-5 w-5 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mb-5">
                          <label
                            htmlFor="phone-number"
                            className="block text-sm font-medium text-gray-700"
                          >
                            <FormattedMessage id="phoneNumber" />
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 flex items-center">
                              <label htmlFor="country" className="sr-only">
                                <FormattedMessage id="country" />
                              </label>
                              <PhoneCodeSelect
                                phoneCode={phoneCode}
                                setPhoneCode={setPhoneCode}
                              />
                            </div>
                            <input
                              type="text"
                              value={phoneNumber}
                              //   onChange={(e) => setPhoneNumber(e.target.value)}
                              onInput={(e) => {
                                setPhoneNumberError(false);
                                phoneMask(e, setPhoneNumber);
                              }}
                              required
                              name="phone-number"
                              id="phone-number"
                              autoComplete="tel-national"
                              className={`${
                                phoneNumberError
                                  ? "ring-1 ring-inset ring-red-300"
                                  : "md:focus:border-indigo-500 md:focus:ring-indigo-50"
                              } block w-full rounded-md border-gray-300 pl-28 0 sm:text-sm`}
                              placeholder="Phone Number"
                            />
                            {phoneNumberError && (
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <ExclamationCircleIcon
                                  className="h-5 w-5 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <CountrySelect
                          data={zoneInfo}
                          selected={countrySelected}
                          setSelected={setCountrySelected}
                          className="mb-5"
                          keyPrefix="feedback-"
                        />

                        <div className="mb-5">
                          <label
                            htmlFor="feedback-email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            <FormattedMessage id="emailAddress" />
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <input
                              value={email}
                              onChange={(e) => {
                                setEmailError(false);
                                setEmail(e.target.value);
                              }}
                              required
                              type="email"
                              name="email"
                              id="feedback-email"
                              className={`${
                                emailError
                                  ? "ring-1 ring-inset ring-red-300"
                                  : "focus:border-indigo-500 focus:ring-indigo-500"
                              } block w-full rounded-md border-gray-300 shadow-sm sm:text-sm`}
                            />
                            {emailError && (
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <ExclamationCircleIcon
                                  className="h-5 w-5 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mb-5">
                          <label
                            htmlFor="feedback-message"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            <FormattedMessage id="message" />
                          </label>
                          <div className="mt-2.5">
                            <textarea
                              name="message"
                              id="feedback-message"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              rows={2}
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <button
                          type="submit"
                          disabled={loading}
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          {buttonContent()}
                        </button>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
