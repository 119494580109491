import googleIcon from "./assets/cookies/googleIcon.png";
import facebookIcon from "./assets/cookies/facebookIcon.png";

export const DEMO_LINK = "https://app.digital-menu.ai/demo";
export const ADMIN_LINK = "https://admin.digital-menu.ai";

export const phoneCodes = [
    { code: "+43", name: "AT" },
    { code: "+32", name: "BE" },
    { code: "+359", name: "BG" },
    { code: "+385", name: "HR" },
    { code: "+357", name: "CY" },
    { code: "+420", name: "CZ" },
    { code: "+45", name: "DK" },
    { code: "+372", name: "EE" },
    { code: "+358", name: "FI" },
    { code: "+33", name: "FR" },
    { code: "+49", name: "DE" },
    { code: "+30", name: "GR" },
    { code: "+36", name: "HU" },
    { code: "+353", name: "IE" },
    { code: "+39", name: "IT" },
    { code: "+371", name: "LV" },
    { code: "+370", name: "LT" },
    { code: "+352", name: "LU" },
    { code: "+356", name: "MT" },
    { code: "+31", name: "NL" },
    { code: "+47", name: "NO" },
    { code: "+48", name: "PL" },
    { code: "+351", name: "PT" },
    { code: "+40", name: "RO" },
    { code: "+381", name: "RS" },
    { code: "+421", name: "SK" },
    { code: "+386", name: "SI" },
    { code: "+34", name: "ES" },
    { code: "+46", name: "SE" },
    { code: "+423", name: "LI" },
    { code: "+44", name: "UK" },
    { code: "+90", name: "TR" },
    { code: "+380", name: "UA" },
    { code: "+7", name: "RU" },
    { code: "+41", name: "CH" },
    { code: "+373", name: "MD" },
    { code: "+995", name: "GE" },
    { code: "+375", name: "BY" },
    { code: "+374", name: "AM" },
    { code: "+376", name: "AD" },
];

export const countries = [
    { code: "US", name: "United States", id: "us" },
    { code: "HU", name: "Hungary", id: "hu" },
    { code: "RU", name: "Russia", id: "ru" },
];

export const zoneInfo = [
    //{ label: 'Asia/Tel_Aviv', lang: 'he' },
    //{ label: 'Asia/Jerusalem', lang: 'he' },
    { label: "Europe/Amsterdam", lang: "nl", country: "Netherlands", shortName: 'NL' },
    { label: "Europe/Andorra", lang: "ca", country: "Andorra", shortName: 'AD' },
    { label: "Europe/Athens", lang: "el", country: "Greece", shortName: 'GR' },
    { label: "Europe/Belfast", lang: "en", country: "Northern Ireland", shortName: 'GB' },
    { label: "Europe/Belgrade", lang: "sr", country: "Serbia", shortName: 'RS' },
    { label: "Europe/Berlin", lang: "de", country: "Germany", shortName: 'DE' },
    { label: "Europe/Bratislava", lang: "sk", country: "Slovakia", shortName: 'SK' },
    { label: "Europe/Brussels", lang: "nl", country: "Belgium", shortName: 'BE' },
    { label: "Europe/Bucharest", lang: "ro", country: "Romania", shortName: 'RO' },
    { label: "Europe/Budapest", lang: "hu", country: "Hungary", shortName: 'HU' },
    //{ label: 'Europe/Busingen', lang: 'de', country: '' },
    { label: "Europe/Chisinau", lang: "ro", country: "Moldova", shortName: 'MD' },
    { label: "Europe/Copenhagen", lang: "da", country: "Denmark", shortName: 'DK' },
    { label: "Europe/Dublin", lang: "en", country: "Ireland", shortName: 'IE' },
    //{ label: 'Europe/Gibraltar', lang: 'en', country: '' },
    //{ label: 'Europe/Guernsey', lang: 'en', country: '' },
    { label: "Europe/Helsinki", lang: "fi", country: "Finland", shortName: 'FI' },
    //{ label: 'Europe/Isle_of_Man', lang: 'en', country: '' },
    { label: "Europe/Istanbul", lang: "tr", country: "Turkey", shortName: 'TR' },
    //{ label: 'Europe/Jersey', lang: 'en', country: '' },
    //{ label: 'Europe/Kaliningrad', lang: 'ru', country: '' },
    { label: "Europe/Kiev", lang: "uk", country: "Ukraine", shortName: 'UA' },
    { label: "Europe/Lisbon", lang: "pt", country: "Portugal", shortName: 'PT' },
    { label: "Europe/Ljubljana", lang: "sl", country: "Slovenia", shortName: 'SI' },
    { label: "Europe/London", lang: "en", country: "England", shortName: 'GB' },
    { label: "Europe/Luxembourg", lang: "fr", country: "Luxembourg", shortName: 'LU' },
    { label: "Europe/Madrid", lang: "es", country: "Spain", shortName: 'ES' },
    { label: "Europe/Malta", lang: "mt", country: "Malta", shortName: 'MT' },
    //{ label: 'Europe/Mariehamn', lang: 'fi', country: '' },
    { label: "Europe/Minsk", lang: "ru", country: "Belarus", shortName: 'BY' },
    { label: "Europe/Monaco", lang: "fr", country: "Monaco", shortName: 'MC' },
    { label: "Europe/Moscow", lang: "ru", country: "Russia", shortName: 'RU' },
    { label: "Europe/Nicosia", lang: "el", country: "Republic of Cyprus", shortName: 'CY' },
    { label: "Europe/Oslo", lang: "no", country: "Norway", shortName: 'NO' },
    { label: "Europe/Paris", lang: "fr", country: "France", shortName: 'FR' },
    { label: "Europe/Podgorica", lang: "sr", country: "Montenegro", shortName: 'ME' },
    { label: "Europe/Prague", lang: "cs", country: "Czech Republic", shortName: 'CZ' },
    { label: "Europe/Riga", lang: "lv", country: "Latvia", shortName: 'LV' },
    { label: "Europe/Rome", lang: "it", country: "Italy", shortName: 'IT' },
    //{ label: 'Europe/Samara', lang: 'ru' },
    { label: "Europe/San_Marino", lang: "it", country: "San Marino", shortName: 'SM' },
    { label: "Europe/Sarajevo", lang: "bs", country: "Bosnia and Herzegovina", shortName: 'BA' },
    //{ label: 'Europe/Simferopol', lang: 'ru', country: '' },
    { label: "Europe/Skopje", lang: "mk", country: "North Macedonia", shortName: 'MK' },
    { label: "Europe/Sofia", lang: "bg", country: "Bulgaria", shortName: 'BG' },
    { label: "Europe/Stockholm", lang: "sv", country: "Sweden", shortName: 'SE' },
    { label: "Europe/Tallinn", lang: "et", country: "Estonia", shortName: 'EE' },
    { label: "Europe/Tirane", lang: "sq", country: "Albania", shortName: 'AL' },
    //{ label: 'Europe/Uzhgorod', lang: 'uk', country: '' },
    { label: "Europe/Vaduz", lang: "de", country: "Liechtenstein", shortName: 'LI' },
    { label: "Europe/Vatican", lang: "it", country: "Vatican", shortName: 'VA' },
    { label: "Europe/Vienna", lang: "de", country: "Austria", shortName: 'AT' },
    { label: "Europe/Vilnius", lang: "lt", country: "Lithuania", shortName: 'LT' },
    //{ label: 'Europe/Volgograd', lang: 'ru', country: '' },
    { label: "Europe/Warsaw", lang: "pl", country: "Poland", shortName: 'PL' },
    { label: "Europe/Zagreb", lang: "hr", country: "Croatia", shortName: 'HR' },
    //{ label: 'Europe/Zaporozhye', lang: 'uk', country: '' },
    { label: "Europe/Zurich", lang: "de", country: "Switzerland", shortName: 'CH' },
];

export const stages = {
    development: "dev",
    staging: "stage",
    production: "prod",
};

export const locales = {
    en: ["en", "en-us", "en-gb", "en-en"],
    es: ["es", "es-es"],
    el: ["el", "el-el"],
    pt: ["pt", "pt-pt"],
};

export const initStateCookies = {
    cookies: [
        {
            id: "google-analytics",
            name: "Google Analytics",
            description: "Helps us measure our audience",
            icon: googleIcon,
            infoLink:
                "https://support.google.com/analytics/answer/6004245?hl=en",
            choose: true,
        },
        {
            id: "meta-pixel",
            name: "Facebook Pixel",
            description: "Identifies visitors from Facebook",
            icon: facebookIcon,
            infoLink: "https://en-gb.facebook.com/business/gdpr",
            choose: true,
        },
    ],
    open: true,
    confirmed: false,
    animationClose: false,
    step: 1,
};
