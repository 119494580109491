export const toogleAllService = ({ cookiesData, setCookiesData, value }) => {
  const newData = cookiesData.cookies.map((service) => ({
    ...service,
    choose: value,
  }));
  setCookiesData((prev) => {
    return {
      ...prev,
      cookies: newData,
    };
  });
};

export const toogleService = ({ cookiesData, setCookiesData, id, value }) => {
  const newData = cookiesData.cookies.map((service) => {
    if (service.id === id) {
      return {
        ...service,
        choose: value,
      };
    } else return service;
  });
  setCookiesData((prev) => {
    return {
      ...prev,
      cookies: newData,
    };
  });
};

export const changeCookieStep = ({ setCookiesData, step }) => {
  setCookiesData((prev) => {
    return {
      ...prev,
      step,
    };
  });
};

export const closeCookieBanner = ({ setCookiesData }) => {
  setCookiesData((prev) => {
    return {
      ...prev,
      open: false,
      animationClose: false,
      confirmed: true,
    };
  });
};

export const openCookieBanner = ({ setCookiesData }) => {
  setCookiesData((prev) => {
    return {
      ...prev,
      open: true,
    };
  });
};

export const closeAnimationCookieBanner = ({ setCookiesData }) => {
  setCookiesData((prev) => {
    return {
      ...prev,
      animationClose: true,
    };
  });
};

export const closeCookie = ({ setCookiesData }) => {
  closeAnimationCookieBanner({ setCookiesData });
  setTimeout(() => {
    closeCookieBanner({ setCookiesData });
    changeCookieStep({ setCookiesData, step: 1 });
  }, 200);
};
