import { Switch } from "@headlessui/react";

import { ServicesCard } from "./ServicesCard";
import { useContext } from "react";
import { CookiesContext } from "../../../context";
import { toogleAllService } from "../actions";
import { classNames } from "../../../helpers";

export const ToggleBlock = () => {
  const { cookiesData, setCookiesData } = useContext(CookiesContext);

  const checkAllServiceChoose = () => {
    const filteredServices = cookiesData.cookies.filter(
      (service) => service.choose
    );
    return filteredServices.length === cookiesData.cookies.length;
  };

  return (
    <div className="mb-10">
      <div className="flex items-center justify-between w-full my-4">
        <p className="text-base font-semibold text-gray-700">Toggle all</p>
        <Switch
          checked={checkAllServiceChoose()}
          onChange={(value) => {
            toogleAllService({ cookiesData, setCookiesData, value });
          }}
          className={classNames(
            checkAllServiceChoose() ? "bg-indigo-600" : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              checkAllServiceChoose() ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
      </div>
      <div className="w-full rounded-lg border-2 border-gray-300 bg-white">
        {cookiesData.cookies.map((service) => (
          <ServicesCard key={service.id} service={service} />
        ))}
      </div>
    </div>
  );
};
