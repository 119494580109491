/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import shape1 from "../assets/shape-1.png";
import shape2 from "../assets/shape-2.png";
import shape3 from "../assets/shape-3.png";
import shape4 from "../assets/shape-4.png";
import shape5 from "../assets/shape-5.png";
import bgShape1 from "../assets/bg-shape-1.png";
import bgShape2 from "../assets/bg-shape-2.png";
import bgShape3 from "../assets/bg-shape-3.png";
import bgShape4 from "../assets/bg-shape-4.png";
import { useEffect, useRef, useState } from "react";
import PhoneHero from "../components/PhoneHero";
import Parallax from "parallax-js";
import { FeedbackFormModal } from "../components/FeedbackFormModal";
import { DEMO_LINK } from "../constants";
import { trackEvent } from "../helpers";
import { FormattedMessage } from "react-intl";

export default function Hero({
  isPhoneHero,
  setIsPhoneHero,
  scrolled,
  setShowToast,
  setMessageToast,
  setTitleToast,
  setTypeToast,
  successSended,
  setSuccessSended,
}) {
  const scene = useRef();
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    new Parallax(scene.current);
  }, []);
  return (
    <div className="relative">
      <FeedbackFormModal
        open={showFeedback}
        setOpen={setShowFeedback}
        setShowToast={setShowToast}
        setTitleToast={setTitleToast}
        setMessageToast={setMessageToast}
        setTypeToast={setTypeToast}
        successSended={successSended}
        setSuccessSended={setSuccessSended}
      />
      <main className="lg:relative min-h-screen relative lg:mb-0 flex items-center ">
        <div className="w-full h-auto sm:h-full absolute z-0">
          <div
            data-relative-input="true"
            ref={scene}
            className="w-full h-full overflow-hidden"
          >
            <img
              data-depth="0.5"
              src={bgShape1}
              style={{
                marginTop: "1vh",
                marginLeft: "2vw",
                objectFit: "contain",
                width: "714px",
                opacity: `${scrolled ? "0%" : "100%"}`,
              }}
            />
            <img
              data-depth="0.7"
              src={bgShape2}
              style={{
                marginTop: "40vh",
                marginLeft: "60vw",
                objectFit: "contain",
                width: "714px",
                opacity: `${scrolled ? "0%" : "100%"}`,
              }}
            />
            <img
              data-depth="0.8"
              src={bgShape3}
              style={{
                marginTop: "30vh",
                marginLeft: "4vw",
                objectFit: "contain",
                width: "714px",
                opacity: `${scrolled ? "0%" : "100%"}`,
              }}
            />
            <img
              data-depth="0.6"
              src={bgShape4}
              style={{
                marginTop: "1vh",
                marginLeft: "70vw",
                objectFit: "contain",
                width: "714px",
                opacity: `${scrolled ? "0%" : "100%"}`,
              }}
            />
            <img
              data-depth="0.1"
              src={shape1}
              style={{
                marginTop: "8vh",
                marginLeft: "2vw",
                objectFit: "contain",
                opacity: `${scrolled ? "0%" : "100%"}`,
              }}
            />
            <img
              data-depth="0.1"
              src={shape2}
              style={{
                marginTop: "80vh",
                marginLeft: "55vw",
                objectFit: "contain",
                opacity: `${scrolled ? "0%" : "100%"}`,
              }}
            />
            <img
              data-depth="0.1"
              src={shape3}
              style={{
                marginTop: "30vh",
                marginLeft: "89vw",
                objectFit: "contain",
                opacity: `${scrolled ? "0%" : "100%"}`,
              }}
            />

            <img
              data-depth="0.1"
              src={shape5}
              style={{
                objectFit: "contain",
                opacity: `${scrolled ? "100%" : "0%"}`,
              }}
            />
          </div>
        </div>
        <h1 className="mx-auto w-full max-w-7xl pb-12 text-center lg:py-64 lg:text-left min-h-full h-full relative z-1">
          <div className="wow fadeIn px-4 sm:px-8 lg:w-1/2 xl:pr-16">
            <h1 className="text-4xl font-black tracking-wide text-gray-900 sm:text-5xl md:text-6xl lg:text-6xl xl:text-6xl mt-28 lg:mt-2">
              <span className="block xl:inline">
                <FormattedMessage id="interactive" />
              </span>{" "}
              <span
                className={`block xl:inline ${
                  scrolled ? "text-white" : "text-indigo-600"
                }`}
              >
                <FormattedMessage id="digitalMenu" />
              </span>{" "}
              <span className="block xl:inline">
                <FormattedMessage id="nativeLang" />
              </span>
            </h1>
            <p
              className={`mx-auto tracking-wide mt-9 max-w-md text-2xl font-normal ${
                scrolled ? "text-indigo-200" : "text-gray-700"
              } px-4 md:px-0 sm:text-2xl md:mt-9 md:max-w-3xl`}
              style={{ fontFamily: "Open Sans" }}
            >
              <FormattedMessage id="boostRevenue" />
            </p>
            <div
              className="mt-10 w-full space-y-4 lg:space-y-0 flex flex-col justify-center items-center lg:justify-start lg:flex-row transition-all"
              style={{ fontFamily: "Open Sans" }}
            >
              <div className="w-4/5 rounded-md shadow">
                {scrolled ? (
                  <button
                    onClick={() => {
                      trackEvent("trackCustom", "ClickFreeTrial", {
                        pagePlace: "main_page",
                      });
                      setShowFeedback(true);
                    }}
                    className="transition-colors duration-300 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-800 px-8 py-3 text-base font-normal text-white md:hover:border-black md:hover:bg-black md:py-4 md:px-10 md:text-lg border-2 border-indigo-600"
                  >
                    <FormattedMessage id="freeTrial" />
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      trackEvent("trackCustom", "ClickFreeTrial", {
                        pagePlace: "main_page",
                      });
                      setShowFeedback(true);
                    }}
                    className="transition-colors duration-300 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-normal text-white md:hover:border-indigo-900 md:hover:bg-indigo-900 md:py-4 md:px-10 md:text-lg border-2 border-indigo-600"
                  >
                    <FormattedMessage id="freeTrial" />
                  </button>
                )}
              </div>
              <div className="mt-3 w-4/5 rounded-md shadow lg:mt-0 lg:ml-3">
                {scrolled ? (
                  <a
                    href={DEMO_LINK}
                    target="blank"
                    className="transition-colors duration-300 flex w-full items-center justify-center rounded-md border border-transparent bg-[rgba(255,255,255,0.8)] px-8 py-3 text-base font-normal text-indigo-600 md:hover:bg-white md:py-4 md:px-10 md:text-lg border-2 border-indigo-600 md:hover:bg-[rgba(255,255,255,1)]"
                  >
                    <FormattedMessage id="liveDemo" />
                  </a>
                ) : (
                  <a
                    href={DEMO_LINK}
                    target="blank"
                    className="transition-colors duration-300 flex w-full items-center justify-center rounded-md border border-transparent bg-[rgba(255,255,255,0.4)] px-8 py-3 text-base font-normal text-indigo-600 md:hover:bg-white md:py-4 md:px-10 md:text-lg border-2 border-indigo-600 md:hover:bg-[rgba(255,255,255,1)]"
                  >
                    <FormattedMessage id="liveDemo" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </h1>
        {/* <PhoneHero isPhoneHero={isPhoneHero} setIsPhoneHero={setIsPhoneHero} /> */}
      </main>
    </div>
  );
}
