import { useState } from "react";
import { FeedbackFormModal } from "../components/FeedbackFormModal";
import { trackEvent } from "../helpers";
import { FormattedMessage } from "react-intl";

export default function ActionCTA({
  setShowToast,
  setMessageToast,
  setTypeToast,
  setTitleToast,
  successSended,
  setSuccessSended,
}) {
  const [showFeedback, setShowFeedback] = useState(false);
  return (
    <div className="bg-gray-50 overflow-x-hidden">
      <FeedbackFormModal
        setShowToast={setShowToast}
        open={showFeedback}
        setOpen={setShowFeedback}
        setTitleToast={setTitleToast}
        setMessageToast={setMessageToast}
        setTypeToast={setTypeToast}
        successSended={successSended}
        setSuccessSended={setSuccessSended}
      />
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between md:py-16 md:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">
            <FormattedMessage id="readyToDiveIn" />
          </span>
          <span className="block text-indigo-600">
            <FormattedMessage id="startYourFree" />
          </span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <button
              onClick={() => {
                trackEvent("trackCustom", "ClickFreeTrial", {
                  pagePlace: "ready_to_dive_in",
                });
                setShowFeedback(true);
              }}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white md:hover:bg-indigo-700"
            >
              <FormattedMessage id="getStarted" />
            </button>
          </div>
          {/*<div className="ml-3 inline-flex rounded-md shadow">
                        <button className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-indigo-600 md:hover:bg-indigo-50 border-2 border-indigo-600">
                            Learn more
                        </button>
                    </div>*/}
        </div>
      </div>
    </div>
  );
}
