import { locales, stages } from "./constants";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const phoneMask = (e, setPhone) => {
  const x = e.target.value.replace(/\D/g, "");
  // .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  // const phone = !x[2]
  //   ? x[1]
  //   : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
  const phone = x;
  e.target.value = phone;
  setPhone(phone);
};

export const trackEvent = (trackType, event, prop = {}) => {
  const currentStage = process.env.REACT_APP_STAGE;
  if (stages[currentStage] === "prod") {
    window.fbq(trackType, event, prop);
  } else {
    console.log("not production stage");
  }
};

export const returnLambdaLink = () => {
  const currentStage = process.env.REACT_APP_STAGE;
  if (stages[currentStage] === "prod") {
    return "https://qwj5fezqp6.execute-api.eu-central-1.amazonaws.com/prod/contact";
  } else if (stages[currentStage] === "stage") {
    return "https://36f2qe6s69.execute-api.eu-central-1.amazonaws.com/staging/contact";
  } else if (stages[currentStage] === "dev") {
    return "https://fkl1jaif6b.execute-api.eu-central-1.amazonaws.com/dev/contact";
  }
};

export const initLocale = ({ setLocale }) => {
  let flag = false;
  for (const [key, value] of Object.entries(locales)) {
    if (navigator.language in value) {
      flag = true;
      setLocale(key);
    }
  }
  if (flag) {
    setLocale("en");
  }
};
