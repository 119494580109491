import { LOCALES } from "./locale";
import el from "./tr/el.json";
import en from "./tr/en.json";
import es from "./tr/es.json";
import hu from "./tr/hu.json";
import pt from "./tr/pt.json";

export const messages = {
  [LOCALES.ENGLISH]: en,
  [LOCALES.HUNGARY]: hu,
  [LOCALES.GREEK]: el,
  [LOCALES.PORTUGUESE]: pt,
  [LOCALES.SPANISH]: es,
};
