import { FormattedMessage } from "react-intl";

export const SuccessFeedbackText = () => {
  return (
    <div className="flex flex-col justify-center items-center space-y-1">
      <div className="text-xl text-center font-medium mb-3">
        <FormattedMessage id="thankYou" />
      </div>
      <div className="text-lg text-center">
        <FormattedMessage id="reachOut" />
      </div>
      <div className="text-lg text-center">
        <FormattedMessage id="connectWithYou" />
      </div>
    </div>
  );
};
