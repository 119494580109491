import ActionSteps from "../components/ActionsSteps";
import Wave from "react-wavify";
import PhoneActions from "../components/PhoneActions";
import { useEffect, useRef, useState } from "react";
import { RoughNotation } from "react-rough-notation";
import shape4 from "../assets/shape-4.png";
import Parallax from "parallax-js";
import { DEMO_LINK } from "../constants";
import { FormattedMessage } from "react-intl";

export default function Actions({
  isPhoneHero,
  setIsPhoneHero,
  scrolled,
  sliderRef,
}) {
  const scene = useRef();
  const container = useRef();
  const [show, setShow] = useState(false);

  useEffect(() => {
    new Parallax(scene.current);

    setTimeout(() => {
      setShow(true);
    }, 400);
  }, []);

  return (
    <div className="flex flex-col">
      {/* <Wave
                fill="#6366f1"
                paused={false}
                options={{
                    height: 20,
                    amplitude: 20,
                    speed: 0.15,
                    points: 4,
                }}
                style={{ position: "relative", top: "10px" }}
            /> */}
      <div className="relative bg-indigo-500 h-full" style={{ zIndex: 1 }}>
        <div className="w-full h-full absolute z-0">
          <div data-relative-input="true" ref={scene} className="w-full h-full">
            <img
              data-depth="0.4"
              src={shape4}
              style={{
                marginTop: "8vh",
                marginLeft: "2vw",
                objectFit: "contain",
                opacity: `${scrolled ? "0%" : "100%"}`,
              }}
            />
          </div>
        </div>
        <div
          ref={container}
          className="relative h-full mx-auto max-w-7xl py-3 lg:py-36 px-4 sm:py-8 sm:px-6 lg:px-8"
        >
          <h1
            className="mt-10 sm:mt-20 mb-[380px] text-2xl font-normal text-indigo-200 sm:text-3xl lg:mb-0 lg:mt-9 lg:max-w-3xl lg:w-3/6"
            style={{ fontFamily: "Open Sans" }}
          >
            <span className="relative z-[1]">
              <FormattedMessage id="canViewTheMenu" />
            </span>
            <RoughNotation
              type="highlight"
              color="#312e81"
              show={show}
              padding={0}
            >
              {" "}
              <FormattedMessage id="browser" />
            </RoughNotation>
          </h1>
          <ActionSteps sliderRef={sliderRef} />
          <div className="flex flex-row mt-12 justify-center lg:justify-start lg:mt-28 lg:mb-[220px]">
            <div className="wow fadeIn rounded-md shadow w-200px">
              <a
                href={DEMO_LINK}
                target="blank"
                className="transition-colors duration-300 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-900 px-8 py-3 text-base font-normal text-white md:hover:bg-white lg:py-4 lg:px-10 lg:text-lg border-2 border-indigo-900 md:hover:bg-black"
              >
                <FormattedMessage id="liveDemo" />
              </a>
            </div>
          </div>
        </div>
        <div className="block lg:hidden">
          <PhoneActions containerRef={container} />
        </div>
      </div>
      <Wave
        fill="#6366f1"
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 4,
        }}
        style={{
          position: "relative",
          top: "0",
          transform: "rotate(180deg)",
          backgroundColor: "#f3f4f6",
        }}
      />
    </div>
  );
}
