import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { phoneCodes, zoneInfo } from "../constants";
import { PhoneCodeSelect } from "../components/PhoneCodeSelect";
import { phoneMask, trackEvent } from "../helpers";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { Spinner } from "../components/Spinner";
import { FormattedMessage } from "react-intl";
import { CountrySelect } from "../components/CountrySelect";

const requestContact = async ({
  setLoading,
  body,
  setShowToast,
  setTitleToast,
  setTypeToast,
  setMessageToast,

  setFirstName,
  setRestaurantName,
  setEmail,
  setMessage,
  setPhoneNumber,
  //setSuccessSended,
}) => {
  try {
    setLoading(true);
    const req = await fetch(
      "https://cvz00os37l.execute-api.eu-central-1.amazonaws.com/default/web-contact-form",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    if (req.ok) {
      trackEvent("track", "SubmitApplication");
      setMessageToast("Message sent successfully");
      setTitleToast("Success");
      setTypeToast("success");
      setShowToast(true);
      setFirstName("");
      setRestaurantName("");
      setEmail("");
      setMessage("");
      setPhoneNumber("");
    } else {
      throw new Error("Something went wrong");
    }
  } catch (e) {
    setMessageToast("Failed to send request. Please try again later");
    setTitleToast("Error");
    setTypeToast("error");
    setShowToast(true);
  } finally {
    setLoading(false);
  }
};

export default function Contact({
  setShowToast,
  setMessageToast,
  setTitleToast,
  setTypeToast,
}) {
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [restaurantName, setRestaurantName] = useState("");
  const [restaurantNameError, setRestaurantNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState("");
  const [phoneCode, setPhoneCode] = useState(
    phoneCodes.find((item) => item.code === "+36")
  );
  const [countrySelected, setCountrySelected] = useState(
    zoneInfo.find((item) => item.label === "Europe/Budapest")
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [loading, setLoading] = useState(false);
  //const [successSended, setSuccessSended] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (!firstName.trim()) {
        setFirstNameError(true);
      } else if (!restaurantName.trim()) {
        setRestaurantNameError(true);
      } else if (!phoneNumber.trim()) {
        setPhoneNumberError(true);
      } else if (!email.trim()) {
        setEmailError(true);
      } else {
        const body = {
          name: firstName,
          restaurant_name: restaurantName,
          phone: phoneCode.code + phoneNumber,
          country: countrySelected.country,
          email: email,
          message: message.trim(),
          is_free_trial_form: false,
          phone_country_code: phoneCode.name,
        };
        requestContact({
          setLoading,
          body,
          setShowToast,
          setTitleToast,
          setTypeToast,
          setMessageToast,

          setFirstName,
          setRestaurantName,
          setEmail,
          setMessage,
          setPhoneNumber,
          //setSuccessSended,
        });
      }
    }
  };

  const buttonContent = () => {
    //if (successSended) {
    //    return (
    //        <div className="flex items-center">
    //            <CheckCircleIcon className="text-white w-5 h-5" />
    //            <div className="text-sm font-semibold text-white ml-2">
    //                Success
    //            </div>
    //        </div>
    //    );
    //}
    if (loading) {
      return <Spinner color="bg-white" />;
    } else {
      return <FormattedMessage id="sendMessage" />;
    }
  };

  return (
    <div className="relative isolate bg-gray-50 overflow-x-hidden" id="contact">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
              <svg
                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width={200}
                    height={200}
                    x="100%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <rect width="100%" height="100%" strokeWidth={0} fill="white" />
                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                  <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                </svg>
                <rect
                  width="100%"
                  height="100%"
                  strokeWidth={0}
                  fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                />
              </svg>
            </div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              <FormattedMessage id="getInTouch" />
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              <FormattedMessage id="haveAnyQuestions" />
            </p>
            <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
              {/*<div className="flex gap-x-4">
                                <dt className="flex-none">
                                    <span className="sr-only">Address</span>
                                    <BuildingOffice2Icon
                                        className="h-7 w-6 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </dt>
                                <dd>
                                    545 Mavis Island
                                    <br />
                                    Chicago, IL 99191
                                </dd>
                            </div>*/}
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Telephone</span>
                  <PhoneIcon
                    className="h-7 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  <a
                    className="hover:text-gray-900"
                    href="tel:+36 (305) 902-253"
                  >
                    +36 (305) 902-253
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Email</span>
                  <EnvelopeIcon
                    className="h-7 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  <a
                    className="hover:text-gray-900"
                    href="mailto:zolotareva@digital-menu.ai"
                  >
                    zolotareva@digital-menu.ai
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48"
        >
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-5 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  <FormattedMessage id="name" />
                </label>
                <div className="relative mt-2.5 rounded-md shadow-sm">
                  <input
                    type="text"
                    name="first-name"
                    required
                    value={firstName}
                    onChange={(e) => {
                      setFirstNameError(false);
                      setFirstName(e.target.value);
                    }}
                    id="first-name"
                    autoComplete="given-name"
                    className={`${
                      firstNameError ? "ring-red-300" : "ring-gray-300"
                    } ring-1 ring-inset block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                  />
                  {firstNameError && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="restaurant-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  <FormattedMessage id="restaurantName" />
                </label>
                <div className="relative mt-2.5 rounded-md shadow-sm">
                  <input
                    type="text"
                    name="restaurant-name"
                    required
                    value={restaurantName}
                    onChange={(e) => {
                      setRestaurantNameError(false);
                      setRestaurantName(e.target.value);
                    }}
                    id="first-name"
                    autoComplete="given-name"
                    className={`${
                      restaurantNameError ? "ring-red-300" : "ring-gray-300"
                    } ring-1 ring-inset block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                  />
                  {restaurantNameError && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="sm:col-span-2">
                <div>
                  <label
                    htmlFor="phone-number"
                    className="block text-sm font-semibold text-gray-700"
                  >
                    <FormattedMessage id="phoneNumber" />
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 flex items-center">
                      <label htmlFor="country" className="sr-only">
                        Country
                      </label>
                      <PhoneCodeSelect
                        phoneCode={phoneCode}
                        setPhoneCode={setPhoneCode}
                      />
                    </div>
                    <input
                      type="text"
                      value={phoneNumber}
                      onInput={(e) => {
                        setPhoneNumberError(false);
                        phoneMask(e, setPhoneNumber);
                      }}
                      required
                      name="phone-number"
                      id="phone-number"
                      autoComplete="tel-national"
                      className={`${
                        phoneNumberError
                          ? "ring-1 ring-inset ring-red-300"
                          : "md:focus:border-indigo-500 md:focus:ring-indigo-50"
                      } block w-full rounded-md border-gray-300 pl-28 0 sm:text-sm`}
                      placeholder="Phone Number"
                    />
                    {phoneNumberError && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="sm:col-span-2">
                <CountrySelect
                  data={zoneInfo}
                  selected={countrySelected}
                  setSelected={setCountrySelected}
                  keyPrefix="contact-"
                  contactPage
                />
              </div>
              <div className="sm:col-span-2">
                <div className="mb-5">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    <FormattedMessage id="email" />
                  </label>
                  <div className="relative mt-2.5 rounded-md shadow-sm">
                    <input
                      type="email"
                      name="email"
                      required
                      value={email}
                      onChange={(e) => {
                        setEmailError(false);
                        setEmail(e.target.value);
                      }}
                      id="email"
                      autoComplete="email"
                      className={`${
                        emailError ? "ring-red-300" : "ring-gray-300"
                      } ring-1 ring-inset block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                    />
                    {emailError && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  <FormattedMessage id="message" />
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="message"
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={4}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                disabled={loading}
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {buttonContent()}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
